import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Rating } from 'react-simple-star-rating'
import { getCommentsListAction } from '../Redux/Action/HomeAction'
import { ImagePath } from '../utilis/ImageUrl'
import { fullName } from '../utilis/commonFunction'
import moment from 'moment'

const CommentsModal = ({ show, setShow, id }) => {
    const dispatch = useDispatch();
    const { commentsList } = useSelector((state) => state?.home)

    console.log("commentsList", commentsList)

    useEffect(() => {
        if (show) {
            dispatch(getCommentsListAction({ id }))
        }
    }, [show])

    return (
        <>
            <Modal
                className="cmn-modal"
                show={show}
                onHide={() => setShow(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h2 className="heading-25 mb-4">Comments</h2>
                    <div className="comments-main-section">
                        {
                            Array.isArray(commentsList?.data) && commentsList?.data?.length > 0 ?
                                commentsList?.data?.map((res) => {
                                    return <>
                                        <div className="comments-popup mb-3" >
                                            <div className="comments-main">
                                                <div className="d-flex">
                                                    <img src={res?.customer_id?.profile_pic ? ImagePath(res?.customer_id?.profile_pic) : require("../Assets/images/user.png")} />
                                                    <div>
                                                        <h2>{fullName(res?.customer_id?.first_name, res?.customer_id?.last_name)}</h2>
                                                        <p>{res?.createdAt && moment(res?.createdAt).fromNow()}</p>
                                                    </div>
                                                </div>
                                                <Rating
                                                    disableFillHover={true}
                                                    readonly={true}
                                                    initialValue={res?.rating ?? 0} />
                                            </div>
                                            <p>
                                                {res?.description}
                                            </p>
                                        </div>
                                    </>
                                })
                                : <p>
                                    No Data Found
                                </p>

                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CommentsModal