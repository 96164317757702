import { createAsyncThunk } from "@reduxjs/toolkit";
import CommonApi from "../../Service/CommonApi";
import { constructQueryString } from "../../utilis/commonFunction";

const addOrderAction = createAsyncThunk(
  "add/order",
  async (reqBody, thunkAPI) => {
    try {
      const response = await CommonApi.post("/order", reqBody);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const getListOrderAction = createAsyncThunk(
  "list/order",
  async (reqBody, thunkAPI) => {
    try {
      const queryString = constructQueryString(reqBody);
      const response = await CommonApi.get(
        `/order${queryString ? `?${queryString}` : ""}`,
        reqBody
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const orderDetailsAction = createAsyncThunk(
  "details/order",
  async (reqBody, thunkAPI) => {
    try {
      const response = await CommonApi.get(`/order/${reqBody.id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addPromoCodeAction = createAsyncThunk(
  "add/promocode",
  async (reqBody, thunkAPI) => {
    try {
      const response = await CommonApi.post("/apply-promocode", reqBody);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const orderStatusAction = createAsyncThunk(
  "add/order-status",
  async (reqBody, thunkAPI) => {
    try {
      const response = await CommonApi.post("/order-status", reqBody);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const deliveryRatingAction = createAsyncThunk(
  "add/delivery-rating",
  async (reqBody, thunkAPI) => {
    try {
      const response = await CommonApi.post(
        "/review-delivery-partner",
        reqBody
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const orderRatingAction = createAsyncThunk(
  "add/order-rating",
  async (reqBody, thunkAPI) => {
    try {
      const response = await CommonApi.post("/review-order", reqBody);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const instantDeliveryToogleAction = createAsyncThunk(
  "add/instant-delivery-toogle",
  async (reqBody, thunkAPI) => {
    try {
      const response = await CommonApi.post("/instant-delivery-toogle", reqBody);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export {
  orderDetailsAction,
  getListOrderAction,
  addOrderAction,
  addPromoCodeAction,
  orderStatusAction,
  deliveryRatingAction,
  orderRatingAction,
  instantDeliveryToogleAction
};
