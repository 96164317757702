import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentLanguage: "en"
}

const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguage: (state, action) => {
            console.log("action", action.payload)
            state.currentLanguage = action.payload;
        },
    },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;