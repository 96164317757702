import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function PasswordChanged() {
  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col
              lg={6}
              className="px-0 d-flex align-items-center  justify-content-center"
            >
              <div className="cmn-form">
                <img
                  className="sticker-img"
                  src={require("../Assets/images/Sticker.svg").default}
                />
                <h2 className="heading-30">Password Changed!</h2>
                <p className="sub-16 text-center">
                  Your password has been changed successfully.
                </p>

                <Link to="/login" className="cmn-red-btn mb-0">
                  Back to Login
                </Link>
              </div>
            </Col>
            <Col lg={6} className="px-0">
              <div className="left-img">
                <img src={require("../Assets/images/leftimg.jpeg")} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
