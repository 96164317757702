import React from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function BillingDetails({ billState, orderType }) {

  console.log("billState", billState)
  const { t } = useTranslation();
  return (
    <Col lg={12} className="mt-3">
      <div className="instant-delievery">
        <div className="billing-details-box">
          <div className="left-side">
            <h3>{t("single-order.billing-details")}</h3>
            <ul>
              <li>{t("single-order.quantity")}</li>
              <li>{billState?.total_items}</li>
            </ul>
            <ul>
              <li>{t("single-order.item-total")}</li>
              <li>${billState?.items_total}</li>
            </ul>
            <ul>
              <li>{t("single-order.GST-charges")}</li>
              <li>$0</li>
            </ul>
            {
              orderType === 0 &&
              <ul>
                <li>{t("single-order.delivery-fees")}</li>
                <li>{billState?.delivery_fees ? `+$${billState?.delivery_fees}` : '$0'}</li>
              </ul>
            }
          </div>
          <div className="right-side">
            <ul>
              <li>
                <b>{t("single-order.grand-total")}</b>
              </li>
              <li>${billState?.grand_total} </li>
            </ul>
            <ul>
              <li>{t("single-order.promocode-applied")}</li>
              <li>{billState?.promo_discount ? `-$${billState?.promo_discount}` : '$0'}</li>
            </ul>
            <ul>
              <li className="ad-red-color">{t("single-order.to-pay")}</li>
              <li className="ad-red-color">${billState?.to_pay}</li>
            </ul>
          </div>
        </div>
      </div>
    </Col>
  );
}
