import { createAsyncThunk } from "@reduxjs/toolkit";
import CommonApi from "../../Service/CommonApi";
import { constructQueryString } from "../../utilis/commonFunction";

const getListSubscribeAction = createAsyncThunk(
    'list/subscribe', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            const response = await CommonApi.get(`/subscribe${queryString ? `?${queryString}` : ""}`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

export {
    getListSubscribeAction
}