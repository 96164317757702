import React from "react";
import { Modal } from "react-bootstrap";

const CheckoutAlerts = ({ alertShow, setAlertShow, formik }) => {
  const formattedMessage = alertShow?.message?.replace(/\n/g, "<br />");

  const handleClose = () => {
    setAlertShow({ ...alertShow, modal: false });
    if (alertShow?.type) {
      formik.setFieldValue("instant_delivery", false);
    }
  };

  return (
    <Modal
      className="cmn-modal"
      show={alertShow.modal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h2 className="heading-25 mb-4 px-4">
          {alertShow?.type === 3
            ? `Instant delivery`
            : `Delivery Alert`}
        </h2>
        <div className="promocodes-wrap">
          <div dangerouslySetInnerHTML={{ __html: formattedMessage }} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CheckoutAlerts;
