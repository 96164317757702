import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import GMapMarker from '../Components/GMapMarker';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const PickupLocationModal = ({ showLocation, setShowLocation, address }) => {
    const { t } = useTranslation();
    const { orderDetails } = useSelector((state) => state?.order);

    const containerStyle = {
        width: '340px',
        height: '400px'
    };

    return (
        <>
            <Modal
                className="cmn-modal"
                show={showLocation}
                onHide={() => setShowLocation(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                {/* <Modal.Header closeButton></Modal.Header> */}
                <Modal.Body>
                    <div className="cmn-form-fields px-4 m-0">
                        <div className="address-main-sec">
                            <div className="d-flex justify-content-between mb-3">
                                <h2 className="heading-25">Deliveries</h2>
                            </div>
                            <div className="newaddress-main">
                                <Row>
                                    <Col lg={12}>
                                        <GMapMarker locations={orderDetails?.track_locations != null ? orderDetails?.track_locations : []} containerStyle={containerStyle} />
                                    </Col>
                                    <Col lg={12} className='mt-3'>
                                        {
                                            Array.isArray(address) && address?.length > 0 &&
                                            address?.map((res) => {
                                                return <>
                                                    <div className="promocode-inner m-2" key={res?._id}>
                                                        <p>Shop: <b className='text-dark'>{res?.name}</b></p>
                                                        <p>{res?.address}</p>
                                                    </div>
                                                </>
                                            })
                                        }
                                    </Col>
                                    <Col lg={12}>
                                        <Link to={`/single-order/${orderDetails?._id}?type=1`} className='no-underline'>
                                            <button type="button" className="cmn-red-btn mb-0 w-100">
                                                View
                                            </button>
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default PickupLocationModal