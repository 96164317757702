import { ErrorMessage, Field, Formik, useFormik } from 'formik'
import React, { useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { contactUsAction } from '../Redux/Action/AuthAction';


const ContactUsComp = () => {
    const dispatch = useDispatch();
    const [isLoader, setIsLoader] = useState(false);

    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email format')
            .required('Email is required'),
        description: Yup.string().required('Description is required'),
    });

    const formik = useFormik({
        initialValues: {
            email: "",
            name: "",
            description: ""
        },
        validationSchema,
        onSubmit: async (values) => {
            console.log("values", values)
            let payload = {
                email: values?.email,
                name: values.name,
                message: values?.description
            };
            setIsLoader(true);
            await dispatch(contactUsAction(payload)).then((res) => {
                console.log("res", res);
                if (res?.payload?.status === 200) {
                    toast.success(res?.payload?.message)
                } else {
                    toast.error(res?.payload?.message);
                }
                setIsLoader(false);
                formik.resetForm();
            });
        },
    });



    return (
        <>
            <Form onSubmit={formik.handleSubmit}>
                <div className="form-set">
                    <InputGroup className="mb-4">
                        <InputGroup.Text id="basic-addon1">
                            <svg
                                width="12"
                                height="14"
                                viewBox="0 0 12 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.01764 8.28074C6.65651 8.28074 7.29538 8.27928 7.93416 8.28098C9.98984 8.28643 11.6762 9.63656 11.9715 11.5343C12.0168 11.8251 12.0074 12.1366 11.9482 12.4251C11.8685 12.8136 11.5917 13.0932 11.206 13.2678C10.5243 13.5762 9.79231 13.7156 9.05053 13.8185C7.67154 14.0097 6.28314 14.0247 4.89473 13.9771C3.71798 13.9368 2.54602 13.8355 1.41685 13.491C1.17897 13.4185 0.945013 13.3276 0.721425 13.2225C0.126384 12.9429 -0.0369937 12.4501 0.00666074 11.8765C0.139715 10.1274 1.42617 8.73858 3.26471 8.36311C3.52568 8.30982 3.79823 8.28886 4.066 8.28431C4.71637 8.27335 5.36709 8.28074 6.01764 8.28074Z"
                                    fill="#8391A1"
                                />
                                <path
                                    d="M6.01206 4.42833e-06C8.06565 0.00309138 9.70535 1.53495 9.70256 3.4478C9.69986 5.32832 8.03829 6.86343 6.00709 6.86213C3.95386 6.86083 2.31146 5.31727 2.31668 3.39386C2.32183 1.52447 3.98505 -0.00300128 6.01206 4.42833e-06Z"
                                    fill="#8391A1"
                                />
                            </svg>
                        </InputGroup.Text>
                        <Form.Control
                            className="border-right-o"
                            name="name"
                            placeholder="Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />

                    </InputGroup>

                    {formik.touched.name && formik.errors.name ? (
                        <div className="text-danger mb-3">
                            {formik.errors.name}
                        </div>
                    ) : null}

                    <InputGroup className="mb-4">
                        <InputGroup.Text id="basic-addon1">
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.48667 1.89312C6.34828 1.89312 6.22357 1.89312 6.09874 1.89312C5.48091 1.89312 4.86286 1.90349 4.24525 1.89015C3.64848 1.87724 3.24446 2.31953 3.28725 2.84826C3.30257 3.03836 3.29155 3.23089 3.28923 3.42232C3.28493 3.77738 3.04852 4.01765 2.70823 4.01589C2.37511 4.01412 2.14178 3.77683 2.14542 3.42651C2.14906 3.0774 2.13021 2.72267 2.18468 2.38051C2.32119 1.52262 3.14092 0.796179 4.01215 0.770266C4.90256 0.743912 5.79429 0.758577 6.68548 0.760783C6.80346 0.761114 6.88892 0.729908 6.97416 0.645553C7.63687 -0.010871 8.40521 -0.161056 9.27622 0.169307C10.4586 0.617765 11.6445 1.05696 12.8271 1.50509C13.5074 1.76301 13.9961 2.48195 13.9985 3.2686C14.0062 5.72945 14.0076 8.1904 13.9981 10.6512C13.9946 11.5691 13.5399 12.2023 12.6824 12.5302C11.5164 12.9761 10.3464 13.411 9.17885 13.8528C8.50622 14.1073 7.86986 14.0291 7.2731 13.6396C7.15224 13.5608 7.06634 13.43 6.95188 13.3389C6.88628 13.2868 6.79575 13.2368 6.71602 13.2361C5.8631 13.2288 5.01018 13.2365 4.15726 13.2301C3.20135 13.223 2.39805 12.6158 2.20784 11.6952C2.12304 11.2847 2.14829 10.8477 2.15733 10.4235C2.16196 10.2035 2.40069 10.0512 2.67537 10.0324C2.93075 10.015 3.17257 10.1682 3.25075 10.4065C3.27578 10.4828 3.28647 10.5665 3.28824 10.6472C3.29331 10.8767 3.28625 11.1065 3.29133 11.3361C3.30037 11.7463 3.64154 12.1133 4.04776 12.1541C4.10202 12.1595 4.15704 12.1572 4.21173 12.1572C4.96585 12.1573 5.71997 12.1573 6.48689 12.1573C6.48667 8.7381 6.48667 5.32907 6.48667 1.89312Z"
                                    fill="#8391A1"
                                />
                                <path
                                    d="M3.46125 6.52933C3.31426 6.38389 3.16551 6.2401 3.02051 6.09267C2.78531 5.85361 2.77318 5.52589 2.98831 5.30029C3.20289 5.07523 3.56027 5.06961 3.79657 5.30029C4.23489 5.72801 4.6667 6.16236 5.10126 6.59395C5.19124 6.68337 5.29908 6.76111 5.3669 6.8651C5.46933 7.0219 5.44739 7.15841 5.31694 7.29338C4.99055 7.63112 4.65964 7.96469 4.32917 8.29847C4.1599 8.46938 3.98998 8.63985 3.81565 8.8057C3.57295 9.03649 3.23002 9.02822 3.0322 8.79191C2.8228 8.54172 2.87705 8.19118 3.15603 7.99082C3.27346 7.90646 3.39079 7.82222 3.49675 7.70148C3.43942 7.70148 3.38208 7.70148 3.32474 7.70148C2.45539 7.70148 1.58593 7.70159 0.716574 7.70126C0.645562 7.70126 0.574329 7.70059 0.503537 7.6953C0.190818 7.67192 0.0113017 7.48028 0.000385186 7.16083C-0.00997998 6.85627 0.189825 6.6117 0.481373 6.57675C0.557127 6.5677 0.634094 6.56726 0.710509 6.56715C1.56343 6.56649 2.41646 6.56671 3.26938 6.56671C3.32805 6.56671 3.3866 6.56671 3.44526 6.56671C3.45077 6.55414 3.45607 6.54179 3.46125 6.52933Z"
                                    fill="#8391A1"
                                />
                            </svg>
                        </InputGroup.Text>

                        <Form.Control
                            className="border-right-o"
                            name="email"
                            placeholder="Email Address"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />


                    </InputGroup>
                    {formik.touched.email && formik.errors.email ? (
                        <div className="text-danger mb-3">
                            {formik.errors.email}
                        </div>
                    ) : null}

                    <InputGroup className="mb-3 text-border">
                        <InputGroup.Text id="basic-addon1">
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clip-path="url(#clip0_1894_35474)">
                                    <path
                                        d="M13.5657 5.1845C13.0559 4.75592 12.276 4.80697 11.8334 5.3347L11.5674 5.64937L13.4526 7.23118L13.7066 6.91766C14.1434 6.39813 14.0881 5.62222 13.5657 5.1845ZM8.24139 9.58469L8.10109 9.75072C7.97889 9.89721 7.89213 10.0699 7.84756 10.2554L7.46625 11.8512C7.4486 11.9252 7.45191 12.0028 7.47582 12.0751C7.49973 12.1474 7.54329 12.2116 7.60163 12.2605C7.7174 12.3578 7.8807 12.3861 8.02822 12.323L9.53385 11.6701C9.70845 11.594 9.86318 11.4788 9.98606 11.3333L10.1269 11.1667L8.24139 9.58469ZM11.0381 6.27563L8.77071 8.95843L10.6561 10.5404L12.9234 7.85744L11.0381 6.27563ZM10.2825 1.81824L8.72436 0.337824C8.57706 0.19818 8.39586 0.11593 8.20486 0.0625V2.46071H10.6399C10.589 2.21708 10.4648 1.99138 10.2825 1.81824Z"
                                        fill="#8391A1"
                                    />
                                    <path
                                        d="M7.04991 10.065C7.12322 9.75816 7.2694 9.46777 7.47207 9.22504L10.666 5.44575V3.28125H7.79492C7.56821 3.28125 7.38477 3.0978 7.38477 2.87109V0H1.23242C0.553914 0 0.00195312 0.551961 0.00195312 1.23047V12.7695C0.00195312 13.448 0.553914 14 1.23242 14H9.43555C10.1141 14 10.666 13.448 10.666 12.7695V11.7999L10.6128 11.863C10.4113 12.1029 10.1513 12.2964 9.86173 12.4226L8.35489 13.0759C7.93019 13.2593 7.43858 13.1943 7.07477 12.8896C6.71547 12.588 6.55967 12.1174 6.66861 11.6607L7.04991 10.065ZM2.05273 2.46094H6.1543C6.381 2.46094 6.56445 2.64439 6.56445 2.87109C6.56445 3.0978 6.381 3.28125 6.1543 3.28125H2.05273C1.82603 3.28125 1.64258 3.0978 1.64258 2.87109C1.64258 2.64439 1.82603 2.46094 2.05273 2.46094ZM2.05273 4.94922H8.61523C8.84194 4.94922 9.02539 5.13267 9.02539 5.35938C9.02539 5.58608 8.84194 5.76953 8.61523 5.76953H2.05273C1.82603 5.76953 1.64258 5.58608 1.64258 5.35938C1.64258 5.13267 1.82603 4.94922 2.05273 4.94922ZM5.33398 10.6914H2.05273C1.82603 10.6914 1.64258 10.508 1.64258 10.2812C1.64258 10.0545 1.82603 9.87109 2.05273 9.87109H5.33398C5.56069 9.87109 5.74414 10.0545 5.74414 10.2812C5.74414 10.508 5.56069 10.6914 5.33398 10.6914ZM2.05273 8.23047C1.82603 8.23047 1.64258 8.04702 1.64258 7.82031C1.64258 7.59361 1.82603 7.41016 2.05273 7.41016H6.97461C7.20132 7.41016 7.38477 7.59361 7.38477 7.82031C7.38477 8.04702 7.20132 8.23047 6.97461 8.23047H2.05273Z"
                                        fill="#8391A1"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1894_35474">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </InputGroup.Text>
                        <Form.Control
                            as="textarea"
                            name='description'
                            className="border-right-none"
                            placeholder="Add Description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </InputGroup>
                    {formik.touched.description && formik.errors.description ? (
                        <div className="text-danger">
                            {formik.errors.description}
                        </div>
                    ) : null}
                </div>
                <div className="send-btn" >
                    <button type='submit'>
                        {isLoader &&
                            <>
                                <div className="spinner-border spinner-border-sm" role="status" >
                                </div>
                            </>
                        } {" "}
                        Send</button>
                </div>
            </Form>

        </>
    )
}

export default ContactUsComp