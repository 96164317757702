import React, { useState, useEffect } from "react";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  orderDetailsAction,
  orderStatusAction,
} from "../Redux/Action/OrderAction";
import { useNavigate } from "react-router-dom";
import OrderPlacedModal from "../Modals/orderPlacedModal";

const CheckoutForm = ({
  clientSecret,
  intentId,
  loading,
  setLoading,
  setShowPayment,
  setShowSuccess = { setShowSuccess },
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [baseUrl, setBaseUrl] = useState("");
  const [stopBtnRender, setStopBtnRender] = useState(true);

  useEffect(() => {
    const currentUrl = window.location.href;

    const getBaseUrl = (fullUrl) => {
      try {
        const url = new URL(fullUrl);
        return `${url.protocol}//${url.host}`;
      } catch (error) {
        toast.error("Invalid URL");
        return "Invalid URL";
      }
    };

    const baseUrl = getBaseUrl(currentUrl);
    setBaseUrl(baseUrl);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    const { error: submitError } = await elements.submit();
    if (submitError) {
      toast.error(submitError?.message);
      setLoading(false);
      return;
    }

    const response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Set redirect: 'if_required' to prevent automatic redirection if not necessary
      },
      clientSecret,
      redirect: "if_required",
    });

    if (response?.paymentIntent?.status === "succeeded") {
      let res = await dispatch(orderStatusAction({ intent_id: intentId }));
      console.log("orderStatus res", res);
      await dispatch(orderDetailsAction({ id: res?.payload?.data?._id }));
      toast.success("Payment succeeded!");
      setShowPayment(false);
      setShowSuccess(true);
      // navigate('/order-listing');
    } else {
      toast.error(response?.error?.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (clientSecret) {
      setTimeout(() => {
        setStopBtnRender(false);
      }, 1000);
    }
  }, [clientSecret]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div>
          <PaymentElement />
        </div>
        {!stopBtnRender && (
          <div className="checkout-button">
            <button
              type="submit"
              disabled={!stripe || !elements || loading}
              className="w-100 mt-3 mb-3"
            >
              {loading ? "Processing..." : "Pay"}
            </button>
          </div>
        )}
      </Form>
    </>
  );
};

export default CheckoutForm;
