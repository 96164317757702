// import React, { useEffect, useRef, useState } from 'react';
// import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
// const style = {
//   maxWidth: "100%",
//   height: "550px",
//   overflowX: "hidden",
//   overflowY: "hidden",
//   position: "static"
//  };

// const GMapPolyLine = (props) => {
//   const [currentLocation, setCurrentLocation] = useState(null);
//   const [shopLocation, setShopLocation] = useState(null);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const lat = position.coords.latitude;
//           const lng = position.coords.longitude;
//           setCurrentLocation({ lat, lng });
//         },
//         (error) => console.error(error)
//       );
//     }
//   }, []); 

//   useEffect(() => {
//     if (props.shopAddress && props.google) {
//       const geocodeAddress = (address) => {
//         const geocoder = new props.google.maps.Geocoder();
//         geocoder.geocode({ address }, (results, status) => {
//           if (status === 'OK' && results[0]) {
//             const location = results[0].geometry.location;
//             setShopLocation({ lat: location.lat(), lng: location.lng() });
//           } else {
//             console.error('Geocode failed:', status);
//           }
//         });
//       };

//       geocodeAddress(props.shopAddress);
//     }
//   }, [props.shopAddress, props.google]); 

//   useEffect(() => {
//     if (currentLocation && shopLocation && props.google) {
//       const directionsService = new props.google.maps.DirectionsService();
//       const directionsRenderer = new props.google.maps.DirectionsRenderer();

//       const request = {
//         origin: currentLocation,
//         destination: shopLocation,
//         travelMode: 'DRIVING',
//       };

//       directionsService.route(request, (result, status) => {
//         if (status === 'OK') {
//           directionsRenderer.setDirections(result);
//           directionsRenderer.setMap(mapRef.current.map); 
//         } else {
//           console.error('Directions request failed:', status);
//         }
//       });
//     }
//   }, [currentLocation, shopLocation, props.google]); 

//   return (
//     <div className="map-container">
//     <Map style={style} 
//       google={props.google}
//       zoom={12}
//       initialCenter={currentLocation || { lat: 0, lng: 0 }}
//       ref={mapRef}
//     >
//       {/* Marker for current location */}
//       {/* {currentLocation && <Marker position={currentLocation} title="Your Location" />} */}

//       {/* Marker for shop location */}
//       {/* {shopLocation && <Marker position={shopLocation} title="Shop Location" />} */}
//     </Map>
//     </div>
//   );
// };

// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyBY7WePV2Eg7cigEfbsFvjs1GzEHx6GXVA',
// })(GMapPolyLine);



import React, { useState, useEffect, useCallback } from 'react';
import {
  GoogleMap,
  Marker,
  DirectionsService,
  DirectionsRenderer,
  useJsApiLoader
} from '@react-google-maps/api';

const containerStyle = {
  width: '630px',
  height: '550px'
};

const center = {
  lat: -3.745,
  lng: -38.523
};

function GMapPolyLine({ locations = [] }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBY7WePV2Eg7cigEfbsFvjs1GzEHx6GXVA"
  });

  const [map, setMap] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (isLoaded && map && locations.length >= 2) {
      const directionsService = new window.google.maps.DirectionsService();
      const middleLocations = locations.slice(1, -1);

      const waypointData = middleLocations.map((location) => ({
        location: location,
        stopover: true,
      }));

      directionsService.route(
        {
          origin: locations[0],  // Start
          destination: locations[locations.length - 1],  // End
          waypoints: waypointData,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirectionsResponse(result);
          } else {
            console.error(`Error fetching directions: ${result}`);
          }
        }
      );
    }
  }, [isLoaded, map, locations]);

  // Render the map only if it is loaded and locations are available
  if (!isLoaded) return <></>;

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Start marker (first location), if available */}
      {locations[0] && (
        <Marker position={locations[0]} label="Start" />
      )}

      {/* Stop markers (middle locations), if available */}
      {locations.slice(1, -1).map((location, index) => (
        <Marker
          key={index}
          position={location}
          label={`Stop ${index + 1}`}
        />
      ))}

      {/* End marker (last location), if available */}
      {locations[locations?.length - 1] && (
        <Marker
          position={locations[locations.length - 1]}
          label="End"
        />
      )}

      {/* Render directions if available */}
      {directionsResponse && (
        <DirectionsRenderer directions={directionsResponse} />
      )}
    </GoogleMap>
  );
}

export default GMapPolyLine;
