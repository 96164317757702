import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCartAction,
  editCartAction,
  listCartAction,
} from "../Redux/Action/CartAction";
import { ImagePath } from "../utilis/ImageUrl";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "../Modals/deleteModal";

const CartHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cartList } = useSelector((state) => state.cart);
  const [cartItems, setCartItems] = useState({});
  const [tempCartItems, setTempCartItems] = useState({});
  const [cartId, setCartId] = useState("");
  const [deleteAccOpen, setDeleteAccOpen] = useState(false);
  const [checkoutPrice, setCheckoutPrice] = useState(0);

  useEffect(() => {
    dispatch(listCartAction());
  }, [dispatch]);

  useEffect(() => {
    if (cartList?.data) {
      const initialItems = cartList.data.reduce((acc, item) => {
        acc[item._id] = {
          quantity: item.quantity || 1,
          price: item.product.price || 0,
          totalPrice: item.total_price || item.product.price,
        };
        return acc;
      }, {});
      setCartItems(initialItems);
      setTempCartItems(initialItems);
    }
  }, [cartList]);

  const handleQuantityChange = async (id, delta) => {
    const currentItem = cartItems[id];
    const newQuantity = Math.max((currentItem.quantity || 1) + delta, 1);
    setTempCartItems((prevItems) => ({
      ...prevItems,
      [id]: {
        ...prevItems[id],
        quantity: newQuantity,
        totalPrice: newQuantity * prevItems[id].price,
      },
    }));
    let success;
    if (currentItem.quantity != newQuantity) {
      success = await handleSubmit(id, newQuantity);
    }
    if (!success) {
      setTempCartItems((prevItems) => ({
        ...prevItems,
        [id]: {
          ...cartItems[id],
        },
      }));
    }
  };

  const handleDeleteCart = async (id) => {
    setCartId(id);
    setDeleteAccOpen(true);
  };

  const handleSubmit = async (cartId, cartQty) => {
    let formdata = {
      quantity: cartQty,
    };
    try {
      const response = await dispatch(editCartAction({ id: cartId, formdata }));
      if (response?.payload?.status === 200) {
        // toast.success('Quantity updated successfully');
        dispatch(listCartAction())
        setCartItems((prevItems) => ({
          ...prevItems,
          [cartId]: {
            ...prevItems[cartId],
            quantity: cartQty,
            totalPrice: cartQty * prevItems[cartId].price,
          },
        }));
        return true;
      } else {
        toast.error(response?.payload?.message);
        return false;
      }
    } catch (error) {
      toast.error("Failed to update quantity");
      return false;
    }
  };

  const routeNavigate = () => {
    navigate("/cart");
  };


  useEffect(() => {
    if (cartList?.data) {
      const grandTotal = cartList.data.reduce((acc, item) => {
        const total_price = item?.total_price || 0;
        return acc + total_price;
      }, 0);

      console.log("Grand Total Price:", grandTotal);
      setCheckoutPrice(grandTotal);
    }
  }, [cartList]);



  return (
    <>
      <Dropdown className="cart-tab">
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic-two"
          className="position-related"
        >
          <div className={cartList?.data?.length > 0 ? "active-shop" : ""}>
            <svg
              width="30"
              height="25"
              viewBox="0 0 30 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 0.995287C0.259773 0.253749 0.796378 -0.0242489 1.56208 0.0016423C2.41018 0.0302713 3.26008 0.00625736 4.10911 0.00860399C5.89161 0.0135319 7.24384 1.01844 7.76222 2.72617C7.96284 3.38706 8.16408 4.04811 8.34358 4.71479C8.40469 4.94178 8.49662 5.01907 8.73981 5.01727C10.4086 5.00491 12.0776 5.01078 13.7465 5.01078C18.275 5.01078 22.8034 5.01398 27.3319 5.00796C28.1554 5.00687 28.8716 5.23347 29.4139 5.87817C29.962 6.52999 30.1397 7.28123 29.8874 8.09379C29.0364 10.8343 28.1835 13.5746 27.297 16.3038C26.8013 17.83 25.4688 18.7498 23.7848 18.7562C20.086 18.7703 16.387 18.7716 12.6881 18.7559C10.8776 18.7482 9.58251 17.7413 9.06969 16.0056C7.84054 11.8456 6.61288 7.68508 5.38459 3.52481C5.16393 2.77756 4.81293 2.51724 4.02218 2.51661C3.22189 2.51599 2.41988 2.48353 1.62178 2.52592C0.850132 2.56691 0.297722 2.313 0 1.58077C0 1.38561 0 1.19045 0 0.995287Z"
                fill="#8391A1"
              />
              <path
                d="M22.5328 20.0055C23.9297 20.0407 25.0368 21.1876 24.9983 22.5597C24.959 23.9665 23.8051 25.0336 22.3609 24.9987C21.0546 24.9672 19.9479 23.7796 19.9826 22.4464C20.0182 21.0758 21.1722 19.9711 22.5328 20.0055Z"
                fill="#8391A1"
              />
              <path
                d="M16.2432 22.4919C16.253 23.855 15.1482 24.9888 13.7994 24.9999C12.3891 25.0115 11.2548 23.92 11.2467 22.5432C11.2383 21.1267 12.3453 20.0025 13.7473 20.004C15.1255 20.0055 16.2333 21.1097 16.2432 22.4919Z"
                fill="#8391A1"
              />
            </svg>
          </div>
          {cartList?.data?.length > 0 && (
            <span className="shop-count">{cartList.data?.length}</span>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className="dropdown-menu-filters">
            <h2>Cart</h2>
            {Array.isArray(cartList?.data) && cartList?.data?.length > 0 ? (
              cartList?.data?.slice(0, 3).map((res) => {
                const item = tempCartItems[res._id] || {};
                return (
                  <div className="add-products-left mb-3" key={res._id}>
                    <div className="add-product-card">
                      <img
                        className="product-img"
                        src={
                          ImagePath(res?.product?.images[0]) ||
                          require("../Assets/images/cakes.png")
                        }
                        alt={res?.product?.name}
                      />
                      <div className="product-desc">
                        <h4>{res?.product?.name || "N/A"}</h4>
                        <div className="d-flex">
                          <div>
                            <p>{res?.size_id?.name || " "}</p>
                            <h5 className="mb-0">${item.totalPrice || "0"}</h5>
                          </div>
                          <div className="quantity-main align-items-end ms-4">
                            <button
                              onClick={() => handleQuantityChange(res._id, -1)}
                            >
                              <img
                                src={
                                  require("../Assets/images/minus.svg").default
                                }
                                alt="Decrease"
                              />
                            </button>
                            <span>{item.quantity || 1}</span>
                            <button
                              onClick={() => handleQuantityChange(res._id, 1)}
                            >
                              <img
                                src={
                                  require("../Assets/images/add.svg").default
                                }
                                alt="Increase"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() => handleDeleteCart(res._id)}
                    >
                      <img
                        src={require("../Assets/images/delete.svg").default}
                        alt="Delete"
                      />
                      <br />
                      Delete
                    </button>
                  </div>
                );
              })
            ) : (
              <p>No Product Added</p>
            )}

            {cartList?.data?.length > 3 && (
              <p onClick={routeNavigate} className="text-center">
                View All
              </p>
            )}
            {cartList?.data?.length > 0 && (
              <button
                type="button"
                className="cmn-red-btn mb-0 w-100"
                onClick={routeNavigate}
              >
                Proceed to Checkout {`${checkoutPrice && `$${checkoutPrice}`}`}
              </button>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>

      <DeleteModal
        deleteAccOpen={deleteAccOpen}
        setDeleteAccOpen={setDeleteAccOpen}
        type={"cart"}
        id={cartId}
      />
    </>
  );
};

export default CartHeader;
