import React from 'react';

const ProductDetailsSizeCard = ({ data, formik }) => {
    return (
        <>
            <div className="cake-box">
                <h3>Size</h3>
                {data?.product_sizes?.length > 1 && <p>Select any 1 option</p>}
                {Array.isArray(data?.product_sizes) && data?.product_sizes?.length > 0 ? (
                    data?.product_sizes.map((res) => (
                        <div className="cake-size-inner mb-0" key={res?._id}>
                            <h4>{res?.name || 'N/A'} <span>{res?.description ? `(${res?.description})` : ""}</span></h4>
                            <div className="d-flex">
                                <span>${res?.price || '0'}</span>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="size_id"
                                        // id={`size-${res?._id}`}
                                        value={res?._id}  // Send _id instead of name
                                        onChange={formik.handleChange}
                                        checked={formik?.values?.size_id === res?._id}
                                    />
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No Size Available</p>
                )}
            </div>
            <div className="cake-detail-input">
                <textarea
                    className="form-control"
                    id="request"
                    rows="1"
                    placeholder="Add a Request"
                    name="request"
                    value={formik.values.request}
                    onChange={formik.handleChange}
                />
            </div>
        </>
    );
};

export default ProductDetailsSizeCard;
