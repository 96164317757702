import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addPromoCodeAction } from "../Redux/Action/OrderAction";
import { useTranslation } from "react-i18next";

const ApplyPromoCode = ({ formik, handleShow, orderType, setBillState, billState, cartIds, promoId, setPromoId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [applyState, setApplyState] = useState(false);

  const promoApply = (type) => {
    if (type === "remove") {
      promoCodeApplyApi()
      formik.setFieldValue("promoCode", "");
    } else {
      if (formik.values.promoCode) {
        promoCodeApplyApi()
      } else {
        toast.warn("Please fill promo-code")
      }
    }
  }

  console.log("formik.values.promoCode", formik.values.promoCode)

  const promoCodeApplyApi = async () => {
    let payload = {
      cart_ids: cartIds,
    }
    if (orderType === 0) {
      let newValues = {
        schedule_id: formik?.values?.selectedDeliveryCharge,
        instant_delivery: formik?.values?.instant_delivery == true ? 1 : 0
      }
      payload = { ...payload, ...newValues }
    }
    if (applyState === false) {
      payload = { ...payload, promocode_id: promoId }
    } else {
      setPromoId("")
    }
    await dispatch(addPromoCodeAction(payload)).then((res) => {
      if (res?.payload?.status === 200) {
        setBillState(res?.payload?.data)
        setApplyState(!applyState)
      } else {
        toast.error(res?.payload?.message)
      }
    })
  }

  return (
    <>
      <Col lg={12}>
        <div className="promocode-sec">
          <div className="d-flex justify-content-between align-items-center">
            <h2>{t("checkout.promocode.heading")}</h2>
            <h4 onClick={handleShow}>{t("checkout.promocode.view-all")}</h4>
          </div>

          {/* Promocode apply and remove here*/}
          {
            applyState ?
              <div className="promocode-inner promocode-applied">
                <div className="">
                  <h2>{billState?.discount_type == 2 && `$`} {billState?.discount || '0'} {billState?.discount_type == 1 && `%`} {t("checkout.promocode.discount")}</h2>
                  <p>{t("checkout.promocode.success-msg")}</p>
                </div>
                <button type="button" className="border-none" onClick={() => promoApply("remove")}>{t("checkout.promocode.btn.remove")}</button>
              </div>
              :
              <div className="promocode-inner">
                <h3>{t("checkout.promocode.promo-label")}</h3>
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("checkout.promocode.input")}
                    name="promoCode"
                    value={formik.values.promoCode}
                    onChange={formik.handleChange}
                  />
                  <button type="button" onClick={() => promoApply("apply")}>{t("checkout.promocode.btn.apply")}</button>
                </div>
              </div>
          }
        </div>
      </Col>

    </>
  );
};

export default ApplyPromoCode;
