import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const center = {
    lat: -3.745,
    lng: -38.523
};

// Custom marker icons
const redMarkerIcon = "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
const blackMarkerIcon = "http://maps.google.com/mapfiles/ms/icons/green-dot.png";


function GMapMarker({ locations, containerStyle }) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBY7WePV2Eg7cigEfbsFvjs1GzEHx6GXVA"
    });

    const [map, setMap] = useState(null);

    const onLoad = useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds();

        // Dynamically extend the bounds with each location
        locations.forEach((location) => {
            bounds.extend(new window.google.maps.LatLng(location?.lat, location?.lng));
        });

        map.fitBounds(bounds);
        setMap(map);
    }, [locations]);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={7}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            {locations.map((location, index) => (
                <Marker
                    key={index}
                    position={{ lat: location.lat, lng: location.lng }}
                    icon={index === locations.length - 1 ? redMarkerIcon : blackMarkerIcon}
                />
            ))}
        </GoogleMap>
    ) : <></>;
}

export default GMapMarker;
