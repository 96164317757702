import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { ImagePath } from "../utilis/ImageUrl";
import ShopStatus from "../CommonComponent/ShopTimeStatus";
import { useDispatch } from "react-redux";
import { listShopAction, shopLikeAction } from "../Redux/Action/HomeAction";

var settings = {
  infinite: false,
  nav: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default function HomeSlider({ data }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [likes, setLikes] = useState({});

  useEffect(() => {
    const initialLikes = {};
    data?.forEach((shop) => {
      initialLikes[shop._id] = shop.is_shop_liked;
    });
    setLikes(initialLikes);
  }, [data]);

  const SingleProduct = (id) => {
    navigate(`/single-restaurant/${id}`);
  };



  const slider = React.useRef(null);

  const shopLikeApi = async (shopId) => {
    let payload = {
      shop_id: shopId,
    };
    await dispatch(shopLikeAction(payload)).then((res) => {
      if (res?.payload?.status === 200) {
        dispatch(listShopAction())
        setLikes((prevLikes) => ({
          ...prevLikes,
          [shopId]: res?.payload?.data?.is_shop_liked,
        }));
      }
    });
  };

  return (
    <>
      <Slider {...settings} ref={slider} className="slick-slide-wrapper">
        {Array.isArray(data) &&
          data?.length > 0 &&
          data.map((res) => {
            return (
              <div
                className={`slide-main ${res?.is_shop_closed && `closed-add`}`}
                key={res?._id}
              >
                <div
                  className="slide-img"
                  style={{ cursor: "pointer" }}
                  onClick={() => SingleProduct(res?._id)}
                >
                  <img
                    src={
                      res?.shop_image
                        ? ImagePath(res?.shop_image)
                        : require("../Assets/images/yeh-xintong-go3DT3PpIw.png")
                    }
                  />
                </div>
                <div className="slide-desc">
                  <h2>{res?.name}</h2>
                  <ShopStatus
                    shopSchedule={res?.opening_hours}
                    type={"Home"}
                  />
                </div>
                <button
                  className={likes[res?._id] ? "active-heart" : ""}
                  onClick={() => shopLikeApi(res?._id)}
                >
                  <svg
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5445 0.75C11.5015 0.75 10.0015 3.75 10.0015 3.75C10.0015 3.75 8.50147 0.75 5.45747 0.75C2.98447 0.75 1.02647 2.82 1.00047 5.288C0.949469 10.413 5.06647 14.058 9.57947 17.12C9.7038 17.2045 9.85065 17.2496 10.001 17.2496C10.1513 17.2496 10.2981 17.2045 10.4225 17.12C14.9345 14.057 19.0525 10.413 19.0005 5.288C18.9755 2.819 17.0175 0.75 14.5445 0.75Z"
                      stroke="#A72A2F"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                {res?.is_shop_closed && (
                  <img
                    className="close-img"
                    src={require("../Assets/images/closed.svg").default}
                  />
                )}
              </div>
            );
          })}
      </Slider>

      <div className="slick-buttons">
        <button onClick={() => slider?.current?.slickPrev()}>
          <img src={require("../Assets/images/arrowleft.svg").default} />
        </button>
        <button onClick={() => slider?.current?.slickNext()}>
          <img src={require("../Assets/images/arrowright.svg").default} />{" "}
        </button>
      </div>
    </>
  );
}
