import { createSlice } from "@reduxjs/toolkit";
import { dashboardAction, getSupportDetailsAction, getUserProfile } from "../Action/AuthAction";

const initialState = {
    userDetails: {},
    dashboardData: {},
    messageList: []
}

const authSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.userDetails = action?.payload?.data;
            })
            .addCase(dashboardAction.fulfilled, (state, action) => {
                state.dashboardData = action?.payload?.data;
                state.error = action.payload;
            })
            .addCase(getSupportDetailsAction.fulfilled, (state, action) => {
                state.messageList = action?.payload
            });
    },
});

export default authSlice.reducer;