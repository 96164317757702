import React from 'react';
import { convertUTCToLocal, convertUTCToLocalWithAMPM } from '../utilis/commonFunction';

const ShopStatus = ({ shopSchedule, type }) => {
    const today = new Date().toLocaleDateString('en-US', { weekday: 'long' });
    const getCurrentTimeInMinutes = () => {
        const now = new Date();
        return now.getHours() * 60 + now.getMinutes();
    };

    const convertTimeToMinutes = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const todaySchedule = shopSchedule?.find(schedule => schedule.day === today);

    let isOpen = false;

    if (todaySchedule) {
        const currentTime = getCurrentTimeInMinutes();
        const localFromTime = convertUTCToLocal(todaySchedule.from);  // Convert 'from' time to local time
        const localToTime = convertUTCToLocal(todaySchedule.to);      // Convert 'to' time to local time

        const fromTimeInMinutes = convertTimeToMinutes(localFromTime);
        const toTimeInMinutes = convertTimeToMinutes(localToTime);

        if (fromTimeInMinutes < toTimeInMinutes) {
            isOpen = currentTime >= fromTimeInMinutes && currentTime <= toTimeInMinutes;
        } else {
            isOpen = currentTime >= fromTimeInMinutes || currentTime <= toTimeInMinutes;
        }
    }

    return (
        <>
        {/*Closed: Available at ${convertUTCToLocal(todaySchedule.from)} */}
            {todaySchedule && isOpen ? (
                <p>
                    {type === "Home"
                        ? `Available`
                        : `Open Today from ${convertUTCToLocal(todaySchedule.from)} to ${convertUTCToLocal(todaySchedule.to)}`}
                </p>
            ) : (
                <p>Closed</p>
            )}
        </>
    );
};

export default ShopStatus;
