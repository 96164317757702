import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

const PaypalComp = () => {
  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);

  const handleApprove = (orderID) => {
    // Handle successful payment here (e.g., update the order status)
    setPaidFor(true);
    console.log("Payment Successful! Order ID:", orderID);
  };

  if (paidFor) {
    return <div>Thank you for your payment!</div>;
  }


  return (
    <PayPalScriptProvider
      options={{ "client-id": "your-paypal-client-id", currency: "USD" }}
    >
      <PayPalButtons
        style={{ layout: 'vertical' }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: "10.00", // Replace with the amount you want to charge
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            handleApprove(data.orderID);
          });
        }}
        onError={(err) => {
          setError(err);
          console.error("PayPal Checkout onError", err);
        }}
      />
      {error && <div>Error: {error.message}</div>}
    </PayPalScriptProvider>
  );
}



export default PaypalComp;
