import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { ImagePath } from '../utilis/ImageUrl'
import { fullName } from '../utilis/commonFunction'
import { Link } from 'react-router-dom'
import { HomeTitles } from '../utilis/enums'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import i18n from '../i18n';
import InviteFriendModal from '../Modals/InviteFriendModal'
import LangSelectModal from '../Modals/LangSelectModal'

const ProfileHeader = ({ userProfileData }) => {
    const { i18n, t } = useTranslation();
    const [showInvite, setShowInvite] = useState(false);
    const [langShowModal, setLangShowModal] = useState(false);

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic-two">
                    <img src={userProfileData?.profile_pic ? ImagePath(userProfileData?.profile_pic) : require("../Assets/images/Profile-icon.png")} />
                    {fullName(userProfileData?.first_name, userProfileData?.last_name)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/my-profile">
                        {t("header.profile")}
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/cart">
                        {t("header.cart")}
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/order-listing?type=1">
                        {t("header.order")}
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/subscribed-products">
                        {t("header.subscribe")}
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="#" onClick={() => setLangShowModal(true)}>
                        {t("header.lang")}
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="#" onClick={() => setShowInvite(true)}>
                        {t("header.invite")}
                    </Dropdown.Item>
                    {/* <Dropdown.Item as={Link} to={`/wallet`}>
                        Wallet
                    </Dropdown.Item> */}
                    <Dropdown.Item as={Link} to={`/ViewAllPages?title=${HomeTitles.FAVOURITES}`}>
                        {t('header.fav')}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            {/* Invite Friend Modal */}
            <InviteFriendModal showInvite={showInvite} setShowInvite={setShowInvite} />


            {/* Language Modal */}
            <LangSelectModal langShowModal={langShowModal} setLangShowModal={setLangShowModal} />
        </>
    )
}

export default ProfileHeader