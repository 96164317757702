import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Formik, Form as FormikForm, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../Redux/Slice/languageSlice";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

const LangSelectModal = ({ langShowModal, setLangShowModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state) => state?.lang?.currentLanguage);

  const [initialValues, setInitialValues] = useState({
    language: "",
  });

  // Update initialValues when currentLanguage changes
  useEffect(() => {
    setInitialValues({ language: currentLanguage });
  }, [currentLanguage]);

  const handleLanguageChange = (value) => {
    setInitialValues({ language: value });
  };

  const handleSubmit = (values) => {
    dispatch(setLanguage(values.language));
    setLangShowModal(false); // Close modal on save
  };

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, [currentLanguage]);

  return (
    <Modal
      className="cmn-modal"
      show={langShowModal}
      onHide={() => setLangShowModal(false)}
    >
      <Modal.Header closeButton>
        {/* <Modal.Title>Select Language</Modal.Title> */}
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ values }) => (
          <FormikForm>
            <Modal.Body>
              <h2 className="heading-25 mb-4 px-4">{t("select_lang_modal.heading")}</h2>
              <div className="mb-0 px-4">
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label={t("select_lang_modal.eng")}
                    name="language"
                    value="en"
                    checked={values.language === "en"}
                    onChange={() => handleLanguageChange("en")}
                  />
                  <Form.Check
                    type="radio"
                    label={t("select_lang_modal.french")}
                    name="language"
                    value="fr"
                    checked={values.language === "fr"}
                    onChange={() => handleLanguageChange("fr")}
                  />
                  <Form.Check
                    type="radio"
                    label={t("select_lang_modal.dutch")}
                    name="language"
                    value="nl"
                    checked={values.language === "nl"}
                    onChange={() => handleLanguageChange("nl")}
                  />
                  <Form.Check
                    type="radio"
                    label={t("select_lang_modal.german")}
                    name="language"
                    value="de"
                    checked={values.language === "de"}
                    onChange={() => handleLanguageChange("de")}
                  />
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex gap-2">
                <button
                  variant="secondary"
                  className="cmn-red-btn mt-0  mb-0 w-100"
                  onClick={() => setLangShowModal(false)}
                >
                  {t("select_lang_modal.btn.cancel")}
                </button>
                <button
                  variant="primary"
                  type="submit"
                  className="cmn-red-btn mt-0 mb-0 w-100"
                >
                  {t("select_lang_modal.btn.save")}
                </button>
              </div>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default LangSelectModal;
