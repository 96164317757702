import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const token = window.localStorage.getItem("token1");

const PrivateRoute = () => {
  return token ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace />
  );
};

export default PrivateRoute;