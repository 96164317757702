const imageArray = [
    {
      id: 1,
      url: "https://milcake-api.bosselt.com/public/1733470907229_openart-image_9vt_kvj-_1725542853988_raw.jpg",
    },
    {
      id: 2,
      url: "https://milcake-api.bosselt.com/public/1733470949897_openart-image_aaqp2wpi_1725540643477_raw.jpg",
    },
    {
      id: 3,
      url: "https://milcake-api.bosselt.com/public/1733470981888_openart-image_dz471-wu_1725542630242_raw.jpg",
    },
    {
      id: 4,
      url: "https://milcake-api.bosselt.com/public/1733471020307_openart-image_i72xgj5t_1725540975175_raw.jpg",
    },
    {
      id: 5,
      url: "https://milcake-api.bosselt.com/public/1733471054111_openart-image_kwdawmqu_1725541139482_raw.jpg",
    },
    {
      id: 6,
      url: "https://milcake-api.bosselt.com/public/1733471103211_openart-image_nfmvywsk_1725542926151_raw.jpg",
    },
    {
      id: 7,
      url: "https://milcake-api.bosselt.com/public/1733471132836_openart-image_ocncl6ok_1725541085071_raw.jpg",
    },
    {
      id: 8,
      url: "https://milcake-api.bosselt.com/public/1733471164120_openart-image_prerrkph_1725543152913_raw.jpg",
    },
    {
      id: 9,
      url: "https://milcake-api.bosselt.com/public/1733471197909_openart-image_z10pcfgx_1725540940452_raw.jpg",
    },
    {
      id: 10,
      url: "https://milcake-api.bosselt.com/public/1733471227434_openart-image_zoghlgla_1725542699797_raw.jpg",
    },
  ];
  
  export default imageArray;
  