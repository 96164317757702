import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import LogOutModal from "../Modals/logoutModal";
import DeleteModal from "../Modals/deleteModal";
import ChangePasswordModal from "../Modals/changePasswordModal";
import EditUserProfile from "../Modals/editUserProfile";
import Layout from "../Components/Layout";
import TransactionModal from "../Modals/transactionModal";
import MyAddressModal from "../Modals/myAddressModal";
import AddNewAddressModal from "../Modals/addNewAddressModal";
import { fullName } from "../utilis/commonFunction";
import useGetMyProfile from "../Hook/useGetUserProfile";
import { ImagePath } from "../utilis/ImageUrl";
import LangSelectModal from "../Modals/LangSelectModal";
import { useSelector } from "react-redux";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import InviteFriendModal from "../Modals/InviteFriendModal";

export default function MyProfile() {
  const { i18n, t } = useTranslation();
  // const { profile } = t("home");
  const userProfileData = useGetMyProfile();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showTransaction, setShowTransaction] = useState(false);

  const handleCloseTransaction = () => setShowTransaction(false);
  const handleShowTransaction = () => setShowTransaction(true);

  const [showPass, setShowPass] = useState(false);

  const handleClosePass = () => setShowPass(false);
  const handleShowPass = () => setShowPass(true);

  const [showAddress, setShowAddress] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteAccOpen, setDeleteAccOpen] = useState(false);

  const handleCloseAddress = () => setShowAddress(false);
  const handleShowAddress = () => setShowAddress(true);

  const [showNewAddress, setShowNewAddress] = useState(false);

  const handleCloseNewAddress = () => setShowNewAddress(false);
  const handleShowNewAddress = () => setShowNewAddress(true);

  const [langShowModal, setLangShowModal] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  return (
    <Layout>
      <Container>
        <Link to="/" className="mt-4 d-block">
          <img src={require("../Assets/images/backimg.svg").default} />
        </Link>
        <div className="cart-header">
          <h2 style={{ position: "unset" }}>{t("profile.heading")}</h2>
        </div>
        <div className="cart-card mt-4">
          <div className="profile-card">
            <Row>
              <Col lg={12}>
                <div className="profile-top">
                  <div className="profile-img">
                    <img
                      src={
                        userProfileData?.profile_pic
                          ? ImagePath(userProfileData?.profile_pic)
                          : require("../Assets/images/Profile-icon.png")
                      }
                    />
                    <h2>
                      {fullName(
                        userProfileData?.first_name,
                        userProfileData?.last_name
                      )}
                    </h2>
                  </div>
                  <div className="edit-profile">
                    <button onClick={handleShow}>
                      <img src={require("../Assets/images/edit.svg").default} />
                      <h3>{t("profile.edit")}</h3>
                    </button>
                  </div>
                </div>
              </Col>
              <Col lg={4} className="mt-4">
                <div className="profile-desc">
                  <h3>{t("profile.notification")}</h3>
                  <div class="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                    />
                  </div>
                </div>
              </Col>
              <Col lg={4} className="mt-4">
                <div
                  className="profile-desc"
                  onClick={handleShowAddress}
                  style={{ cursor: "pointer" }}
                >
                  <h3>{t("profile.myaddress")}</h3>
                  <img src={require("../Assets/images/left.svg").default} />
                </div>
              </Col>

              <Col lg={4} className="mt-4">
                <div
                  className="profile-desc"
                  onClick={handleShowTransaction}
                  style={{ cursor: "pointer" }}
                >
                  <h3>{t("profile.trans")}</h3>
                  <img src={require("../Assets/images/left.svg").default} />
                </div>
              </Col>
              <Col lg={4} className="mt-3">
                <div
                  className="profile-desc"
                  onClick={() => setLangShowModal(true)}
                >
                  <h3>{t("profile.lang")}</h3>
                  <img src={require("../Assets/images/left.svg").default} />
                </div>
              </Col>
              <Col lg={4} className="mt-3">
                <div className="profile-desc">
                  <Link to={`/support`} className="no-underline">
                    <h3>{t("profile.support")}</h3>
                  </Link>
                  <img src={require("../Assets/images/left.svg").default} />
                </div>
              </Col>
              <Col lg={4} className="mt-3">
                <div
                  className="profile-desc"
                  onClick={handleShowPass}
                  style={{ cursor: "pointer" }}
                >
                  <h3>{t("profile.change_password")}</h3>
                  <img src={require("../Assets/images/left.svg").default} />
                </div>
              </Col>
              <Col lg={4} className="mt-3">
                <div className="profile-desc" onClick={() => setShowInvite(true)}>
                  <h3>{t("profile.invite_friends")}</h3>
                  <img src={require("../Assets/images/left.svg").default} />
                </div>
              </Col>
              <Col lg={4} className="mt-3">
                <Link
                  to="/subscribed-products"
                  style={{ textDecoration: "none" }}
                >
                  <div className="profile-desc">
                    <h3>{t("profile.sub_products")}</h3>
                    <img src={require("../Assets/images/left.svg").default} />
                  </div>
                </Link>
              </Col>

              <Col lg={12} className="mt-4 d-flex justify-content-center">
                <button
                  type="button"
                  className="logout"
                  onClick={() => setIsModalOpen(true)}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.986938 0C3.25543 0 5.52473 0 7.79403 0C7.82824 0.00977958 7.86245 0.022004 7.89748 0.0277088C8.65582 0.161363 9.29197 0.836969 9.32048 1.58511C9.34491 2.23626 9.32944 2.88823 9.32781 3.54021C9.32699 3.70972 9.24717 3.84826 9.11603 3.95176C8.93194 4.09764 8.72505 4.12454 8.51246 4.03C8.29416 3.9322 8.17279 3.75536 8.17116 3.51413C8.16709 2.95343 8.16954 2.39274 8.16872 1.83204C8.16791 1.40174 7.93984 1.16947 7.51465 1.16947C6.70337 1.16866 5.8929 1.16947 5.08162 1.16947C5.04252 1.16947 5.00261 1.17273 4.95863 1.17518C4.9684 1.18903 4.97084 1.19881 4.97736 1.20207C5.61596 1.47346 5.83181 1.79944 5.83181 2.49379C5.83181 5.29727 5.83181 8.10075 5.83181 10.9042C5.83181 10.9613 5.83181 11.0183 5.83181 11.0843C5.89942 11.0843 5.94503 11.0843 5.98983 11.0843C6.49566 11.0843 7.00149 11.0851 7.50732 11.0843C7.94146 11.0835 8.16872 10.8545 8.16872 10.4152C8.16954 9.69074 8.16791 8.96543 8.16954 8.24092C8.16954 8.16432 8.17116 8.08445 8.19071 8.0111C8.26321 7.74053 8.53282 7.55717 8.8008 7.58895C9.10544 7.62399 9.32537 7.84974 9.32699 8.15372C9.3327 8.93283 9.33432 9.71275 9.32862 10.4919C9.32211 11.4943 8.55726 12.2514 7.55537 12.2538C7.03163 12.2554 6.50706 12.2538 5.98331 12.2538C5.93526 12.2538 5.88801 12.2538 5.83018 12.2538C5.83018 12.438 5.83263 12.6018 5.82937 12.7648C5.82692 12.865 5.82774 12.9669 5.80737 13.0639C5.65994 13.7753 4.9627 14.1665 4.25568 13.9318C3.13732 13.561 2.02059 13.1869 0.90304 12.8129C0.280733 12.6051 0.000531943 12.2147 0.000531943 11.557C0.000531943 8.13335 0.000531943 4.70968 0.000531943 1.28683C0.000531943 1.24119 -0.00109713 1.19555 0.00134648 1.14992C0.0282262 0.652787 0.26933 0.300722 0.719769 0.092091C0.804481 0.0521577 0.897339 0.0301537 0.986938 0Z"
                      fill="#A72A2F"
                    />
                    <path
                      d="M13.9992 5.9631C13.8933 6.10734 13.8029 6.26708 13.6783 6.3934C12.9688 7.11383 12.2504 7.82611 11.536 8.54327C11.3813 8.69812 11.2029 8.78287 10.983 8.74294C10.693 8.69078 10.5024 8.45607 10.5016 8.14965C10.4991 7.6297 10.5007 7.11057 10.5007 6.59062C10.5007 6.54091 10.5007 6.49119 10.5007 6.42111C10.4405 6.42111 10.3875 6.42111 10.3354 6.42111C9.62919 6.42111 8.92298 6.42192 8.21678 6.42029C7.89096 6.41948 7.65556 6.23855 7.59854 5.95169C7.52442 5.57925 7.79403 5.25408 8.18664 5.25245C8.90181 5.25 9.61697 5.25163 10.3321 5.25163C10.3818 5.25163 10.4315 5.25163 10.5007 5.25163C10.5007 5.19703 10.5007 5.14895 10.5007 5.10086C10.5007 4.58581 10.5032 4.07075 10.4999 3.55569C10.4983 3.28268 10.605 3.07242 10.86 2.96647C11.1133 2.86053 11.3389 2.9298 11.5319 3.12376C12.2878 3.88249 13.0478 4.63715 13.7996 5.39914C13.886 5.48634 13.934 5.61022 14 5.71698C13.9992 5.79847 13.9992 5.88078 13.9992 5.9631Z"
                      fill="#A72A2F"
                    />
                  </svg>
                  {t("profile.btn.log_out")}
                </button>
              </Col>
              <Col lg={12} className="mt-0 d-flex justify-content-center">
                <button
                  type="button"
                  className="delete-account"
                  onClick={() => setDeleteAccOpen(true)}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.1051 3.49363C1.60381 3.49363 1.13421 3.50419 0.66461 3.49074C0.183487 3.4773 -0.1315 2.97985 0.0538431 2.53714C0.16428 2.27401 0.367868 2.12324 0.658846 2.10308C0.718387 2.09923 0.778888 2.10019 0.839388 2.10019C4.95246 2.10019 9.06553 2.10019 13.1786 2.10115C13.2871 2.10115 13.4004 2.10115 13.5032 2.129C13.8422 2.2212 14.041 2.53906 13.9987 2.89726C13.9603 3.22281 13.6799 3.48306 13.338 3.49074C12.928 3.49939 12.5179 3.49363 12.1078 3.49459C12.0483 3.49459 11.9897 3.49459 11.9052 3.49459C11.9052 3.57813 11.9052 3.64728 11.9052 3.71546C11.9052 6.42262 11.9062 9.12977 11.9042 11.8369C11.9033 12.8213 11.3348 13.627 10.435 13.8978C10.2237 13.9612 9.99512 13.9929 9.77425 13.9938C7.93139 14.0015 6.08756 14.0015 4.2447 13.9967C3.00972 13.9938 2.10798 13.0853 2.10606 11.8456C2.10318 9.13841 2.1051 6.43126 2.1051 3.7241C2.1051 3.65592 2.1051 3.58582 2.1051 3.49363ZM4.90444 8.04748C4.90444 8.62176 4.89484 9.19603 4.91116 9.76935C4.91501 9.91916 4.96879 10.0843 5.04849 10.2111C5.20599 10.4627 5.53345 10.5568 5.81771 10.4675C6.11925 10.3724 6.30748 10.1035 6.30748 9.74918C6.3094 8.61696 6.3094 7.48569 6.30652 6.35347C6.30652 6.25648 6.29115 6.15565 6.26042 6.06441C6.15383 5.74943 5.83308 5.55736 5.51329 5.60634C5.15797 5.66108 4.90924 5.93477 4.90636 6.29201C4.9006 6.87781 4.90444 7.46265 4.90444 8.04748ZM9.10586 8.05805C9.10586 7.48954 9.10682 6.92102 9.10586 6.35251C9.1049 5.91652 8.812 5.60154 8.41059 5.5977C8.01205 5.59482 7.70571 5.91172 7.70475 6.34387C7.70187 7.48089 7.70187 8.61888 7.70475 9.7559C7.70571 10.1871 8.01301 10.5059 8.41059 10.5021C8.81296 10.4992 9.10586 10.1832 9.10682 9.74726C9.10682 9.18451 9.10586 8.6208 9.10586 8.05805Z"
                      fill="#171717"
                    />
                    <path
                      d="M6.99795 1.39243C6.52259 1.39243 6.04723 1.39916 5.57187 1.39051C5.22711 1.38379 4.94958 1.11778 4.90828 0.779748C4.86795 0.443634 5.07922 0.122885 5.40861 0.0268529C5.46047 0.0114877 5.51617 0.00284478 5.56995 0.00284478C6.52643 0.000924134 7.48291 -0.00291714 8.43844 0.00380513C8.78319 0.0066861 9.05977 0.274617 9.10202 0.614572C9.14332 0.950685 8.93204 1.27335 8.60073 1.36555C8.51334 1.38955 8.41731 1.39339 8.32512 1.39339C7.88241 1.39628 7.4397 1.39436 6.99699 1.39436C6.99795 1.39436 6.99795 1.39339 6.99795 1.39243Z"
                      fill="#171717"
                    />
                  </svg>
                  {t("profile.btn.del")}
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </Container>

      {/* LogOut Modal */}
      <LogOutModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

      {/* Delete Modal */}
      <DeleteModal
        deleteAccOpen={deleteAccOpen}
        setDeleteAccOpen={setDeleteAccOpen}
      />

      {/* Edit Profile Modal */}
      <EditUserProfile handleClose={handleClose} show={show} />

      {/* Transaction Modal */}
      <TransactionModal
        showTransaction={showTransaction}
        handleCloseTransaction={handleCloseTransaction}
      />

      {/* Change Password Modal */}
      <ChangePasswordModal
        showPass={showPass}
        handleClosePass={handleClosePass}
      />

      {/* My Addres Modal */}
      <MyAddressModal
        showAddress={showAddress}
        handleCloseAddress={handleCloseAddress}
        handleShowNewAddress={handleShowNewAddress}
      />

      {/* Add New Address */}
      <AddNewAddressModal
        showNewAddress={showNewAddress}
        handleCloseNewAddress={handleCloseNewAddress}
      />

      {/* Invite Friend Modal */}
      <InviteFriendModal showInvite={showInvite} setShowInvite={setShowInvite} />

      {/* Language Modal */}
      <LangSelectModal
        langShowModal={langShowModal}
        setLangShowModal={setLangShowModal}
      />
    </Layout>
  );
}
