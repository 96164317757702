import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { withdrawAmtAction } from '../Redux/Action/HomeAction';


const WithdrawAmount = ({ isModalOpenWithdraw, setIsModalOpenWithdraw, walletBal }) => {
    const dispatch = useDispatch();
    const [isLoader, setIsLoader] = useState(false);

    const initialValues = {
        amount: ""
    }
    const validationSchema = Yup.object({
        amount: Yup.number()
            .typeError("Amount must be a number")
            .required("Amount is required*")
            .positive("Amount must be greater than zero")
            .max(walletBal, `Amount cannot exceed ${walletBal}`),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            console.log("values", values)
            let payload = {
                amount: Number(values?.amount)
            }
            setIsLoader(true);
            let apiRes = await dispatch(withdrawAmtAction(payload))
            console.log("apiRes", apiRes?.payload)
            if (apiRes?.payload?.status === 200) {
                toast.success(apiRes?.payload?.message)
            } else {
                toast.error(apiRes?.payload?.message)
            }
            setIsLoader(false);
            setIsModalOpenWithdraw(false)
            resetForm();
        },
    });

    const closeModal = () => {
        setIsModalOpenWithdraw(false);
        formik.resetForm();
    }

    return (
        <>
            <Modal show={isModalOpenWithdraw} >
                <Modal.Header closeButton onClick={closeModal} className="border-none"> </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <Row>
                            <div className="commmn-modal">
                                <h2>Amount Withdraw</h2>
                                <Col lg={12}>
                                    <div className="form-set height-none">
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder="Enter amount here "
                                                name="amount"
                                                aria-describedby="basic-addon1"
                                                value={formik.values.amount}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </InputGroup>
                                        <div className="error-input-mt">
                                            <span className="text-danger">
                                                {formik.touched.amount &&
                                                    formik.errors.amount}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                        <button type='submit' className="btn-fill">
                                            {isLoader &&
                                                <>
                                                    <div class="spinner-border spinner-border-sm" role="status" >
                                                    </div>{" "}
                                                </>
                                            }
                                            Submit</button>
                                    </div>
                                </Col>
                            </div>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default WithdrawAmount