import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ForgotPasswordAction } from "../Redux/Action/AuthAction";
import { useDispatch } from "react-redux";
import { countryCode } from "../utilis/countryCode";
import Select from "react-select";
import { toast } from "react-toastify";
import { Encryptedid } from "../utilis/BcryptEcryptId";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);

  const validationSchema = Yup.object({
    phone_no: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number is not valid")
      .required("Phone number is required"),
  });

  const transformedCountryCodeOptions = countryCode?.map((option) => ({
    value: option.dial_code,
    label: ` ${option.dial_code}`,
  }));

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#fbd9d3" : "#fff",
      color: "#333",
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const formik = useFormik({
    initialValues: {
      phone_no: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);
      let userData = {
        role: 1,
        phone_no: values?.phone_no,
        country_code: values?.country_code ?? "+32",
      };
      setIsLoader(true);
      dispatch(ForgotPasswordAction(userData)).then((res) => {
        console.log("res", res);
        if (res?.payload?.status === 200) {
          toast.success(
            `${res?.payload?.message} OTP is ${res?.payload?.data?.otp}`
          );
          navigate(
            `/LoginOtp?phone=${Encryptedid(
              (values?.phone_no).toString()
            )}&countrycode=${Encryptedid(values?.country_code ?? "+32")}&otp=${
              res?.payload?.data?.otp
            }`
          );
        } else {
          toast.error(res?.payload?.message);
        }
        // formik.resetForm();
        setIsLoader(false);
      });
    },
  });

  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col lg={6} className="px-0">
              <div className="left-img">
                <img
                  src={require("../Assets/images/leftimg.jpeg")}
                  alt="left"
                />
              </div>
            </Col>
            <Col
              lg={6}
              className="px-0 d-flex align-items-center justify-content-center"
            >
              <div className="cmn-form">
                <h2 className="heading-30">Forgot Password?</h2>
                <p className="sub-16 text-center">
                  Enter your email to reset your password
                </p>

                <Form onSubmit={formik.handleSubmit}>
                  <div className="cmn-form-fields">
                    <Col lg={6}>
                      <div className="height-none country-code-line">
                        <div className="left-number">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                          >
                            <path
                              d="M3.55748 0C3.87491 0.0795484 4.18601 0.173436 4.4419 0.391426C4.48805 0.430859 4.53745 0.467048 4.58035 0.509725C5.33708 1.26407 6.09483 2.01739 6.84831 2.77498C7.46658 3.39651 7.5382 4.29527 7.02728 4.94173C6.85532 5.15921 6.64062 5.31985 6.37824 5.41322C5.9039 5.58222 5.53588 5.86986 5.33486 6.34373C5.20546 6.64878 5.18478 6.95861 5.27059 7.2821C5.50066 8.14791 5.97757 8.8717 6.55841 9.53506C7.24248 10.3165 8.02348 10.9797 8.96379 11.4376C9.35763 11.6293 9.76291 11.7928 10.2195 11.7291C10.7971 11.6486 11.2268 11.3642 11.4899 10.8355C11.6071 10.5997 11.7044 10.3406 11.8734 10.1465C12.4242 9.51372 13.3912 9.45227 14.0452 9.98043C14.1224 10.0429 14.1952 10.1117 14.2657 10.1819C15.0027 10.9167 15.7285 11.6632 16.4784 12.3848C17.2109 13.0895 17.1356 14.188 16.4791 14.8472C15.9947 15.3336 15.502 15.812 15.0238 16.3044C14.5728 16.7685 14.0149 16.9684 13.3857 16.9959C12.5057 17.0343 11.6779 16.7991 10.8689 16.4863C10.0251 16.1601 9.23233 15.7316 8.47509 15.2407C6.28013 13.8172 4.40241 12.0475 2.7958 9.99016C1.92865 8.87989 1.18013 7.69401 0.63485 6.38982C0.318451 5.63292 0.0789716 4.85416 0.0258111 4.02829C0.0227343 3.98015 0.00888858 3.9327 0 3.8849C0 3.8185 0 3.75209 0 3.68569C0.0114526 3.60477 0.0264948 3.52403 0.0336741 3.44277C0.0846125 2.86852 0.282725 2.36324 0.704249 1.95338C1.10065 1.56809 1.49516 1.18025 1.8772 0.780975C2.22146 0.421299 2.58179 0.0973018 3.09203 0C3.24724 0 3.40245 0 3.55748 0Z"
                              fill="#8391A1"
                            />
                          </svg>

                          {/* <Select
                            name="country_code"
                            className="react-select-set"
                            aria-label="Default select example"
                            styles={customStyles}
                            isSearchable={false}
                            options={transformedCountryCodeOptions}
                            value={transformedCountryCodeOptions.find(
                              (option) =>
                                option.value === formik.values.country_code
                            )}
                            onChange={(option) =>
                              formik.setFieldValue("country_code", option.value)
                            }
                            onBlur={formik.handleBlur}
                          />
                           */}

                          <Select
                            name="country_code"
                            className="react-select-set "
                            aria-label="Default select example"
                            styles={customStyles}
                            isSearchable={false}
                            defaultValue={transformedCountryCodeOptions.find(
                              (option) => option.value === "+32"
                            )}
                            options={transformedCountryCodeOptions}
                            value={transformedCountryCodeOptions.find(
                              (option) =>
                                option.value === formik.values.country_code
                            )}
                            onChange={(option) =>
                              formik.setFieldValue("country_code", option.value)
                            }
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        <span className="border-between">|</span>
                        <Form.Group className="">
                          <Form.Control
                            className="border-right-o"
                            placeholder="Phone Number"
                            aria-describedby="basic-addon1"
                            name="phone_no"
                            type="number"
                            value={formik.values.phone_no}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </Form.Group>
                      </div>
                      {formik.touched.phone_no && formik.errors.phone_no ? (
                        <div className="text-danger">
                          {formik.errors.phone_no}
                        </div>
                      ) : null}
                    </Col>

                    {/* <Form.Group className="mb-3 form-inner">
                      <img src={require("../Assets/images/envelop.svg").default} alt="envelope" />
                      <Form.Control
                        type="email"
                        placeholder="Email Address"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-danger">{formik.errors.email}</div>
                      ) : null}
                    </Form.Group> */}
                  </div>
                  <Button
                    type="submit"
                    className="cmn-red-btn mb-0"
                    disabled={isLoader ? true : false}
                  >
                    {isLoader && (
                      <>
                        <div
                          class="spinner-border spinner-border-sm"
                          role="status"
                        ></div>{" "}
                      </>
                    )}
                    Send OTP
                  </Button>

                  <div className="mt-2 text-center">
                    <button
                      className="btn btn-link resend-btn-txt"
                      onClick={() => navigate("/login")}
                    >
                      {"Back to Login"}
                    </button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      {/* Uncomment and use as needed */}
      {/* <img className="cmn-first-img" src={require("../Assets/images/donut.png")} alt="donut" />
      <img className="cmn-second-img" src={require("../Assets/images/cupcake.png")} alt="cupcake" />
      <img className="cmn-third-img" src={require("../Assets/images/cake.png")} alt="cake" /> */}
    </div>
  );
}
