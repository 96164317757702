import React, { useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { Col, Container, Row, Button, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Layout from "../Components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getSupportDetailsAction } from "../Redux/Action/AuthAction";
import { useFormik } from "formik";
import moment from "moment";
import { ImagePath } from "../utilis/ImageUrl";

export default function Support() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { messageList } = useSelector((state) => state.authData)

  const { setSocket } = useSelector((state) => state.socket);
  const localUserId = localStorage.getItem("userId");

  const [state, setState] = useState([]);
  const [sendSocketId, setSendSocketIds] = useState({
    sender_id: "",
    receiver_id: ""
  });

  const messagesEndRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    onSubmit: (values) => {
      sendMessage(values.message);
      formik.resetForm();
    },
  });

  const sendMessage = (message) => {
    if (message.trim() !== "") {
      const newMessage = {
        sender_id: sendSocketId?.sender_id,
        receiver_id: sendSocketId?.receiver_id,
        message: message,
        message_type: 0,
        type: 0,
      };
      setSocket.emit("send_message", newMessage);
    }
  };

  useEffect(() => {
    dispatch(getSupportDetailsAction()).then((res) => {
      if (res?.payload?.status === 200) {
        let newValues = {
          sender_id: res?.payload?.sender_id,
          receiver_id: res?.payload?.receiver_id
        };
        setSendSocketIds({ ...sendSocketId, ...newValues });
        setState(res?.payload?.data);
      }
    });
  }, []);

  useEffect(() => {
    if (setSocket) {
      setSocket.on('get_message', (res) => {
        console.log("get", res)
        setState((prevState) => [...prevState, res]);
      });
      return () => {
        setSocket.off('get_message');
      };
    }
  }, [setSocket]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [state]);

  const routeBack = () => {
    navigate(-1);
  }


  return (
    <Layout>
      <Container>
        <Link onClick={routeBack} className="mt-4 d-block">
          <img src={require("../Assets/images/backimg.svg").default} />
        </Link>
        <div className="cart-header">
          <h2 style={{ position: "relative" }}>Support </h2>
        </div>
        <div className="cart-card mt-4">
          <Row>
            <Col lg={12}>
              <div className="support-chat-box-main">
                <div className="support-inner">
                  {/* <h2>Chat</h2> */}
                  <div className="support-msg-box">
                    {Array.isArray(state) && state.length > 0 ? (
                      state.map((res, index) => {
                        const isSender = res?.sender_id === localUserId;
                        const messageBoxStyle = {
                          display: "flex",
                          justifyContent: isSender ? "flex-end" : "flex-start",
                          margin: "10px 0",
                        };

                        return (
                          <div
                            className={`user-box ${isSender && "right-side-chat"}`}
                            key={res?._id || index}
                            style={messageBoxStyle}
                          >
                            <div>
                              <div className="user-top-hdng">
                                <img src={res?.user?.profile_pic ? ImagePath(res?.user?.profile_pic) : require("../Assets/images/adminplaceholder.png")} />
                                <h3>{res?.user?.first_name} {res?.user?.last_name}</h3>
                               
                              </div>
                              <div className="user-msg-box">
                                <p>{res?.message || "N/A"}</p>
                              </div>
                              <h4>{moment(res?.createAt).format("hh:mm A")}</h4>
                            </div>

                          </div>
                        );
                      })
                    ) : (
                      <p>No Message yet</p>
                    )}
                    <div ref={messagesEndRef} />

                  </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <div class="send-box">
                    {
                      messageList?.chat_status === 1 ?
                        <>
                          <div className="text-center mb-3">
                            <b>Chat is Closed by Super Admin</b>
                          </div>
                        </> :
                        <>
                          <div class="mb-3 input-group chat-msg-bar">
                            <input
                              placeholder="Type message.."
                              aria-label="send"
                              aria-describedby="basic-addon2"
                              name="message"
                              type="text"
                              className="send-feild form-control"
                              value={formik.values.message}
                              onChange={formik.handleChange}
                            />
                            <button
                              class="input-group-text"
                              id="basic-addon2"
                              type="submit"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="18"
                                viewBox="0 0 16 18"
                                fill="none"
                              >
                                <path
                                  d="M0.523716 17.2572C0.110234 16.7692 -0.162488 16.0958 0.108768 15.3141C0.544243 14.0606 1.01344 12.8205 1.47531 11.576C1.69671 10.9782 1.93864 10.3879 2.16005 9.79155C2.20697 9.66398 2.26562 9.62838 2.39611 9.62986C4.24651 9.63431 6.09838 9.63134 7.94879 9.63431C8.17312 9.63431 8.3696 9.58536 8.51476 9.39994C8.67018 9.20266 8.70244 8.98609 8.60273 8.75765C8.50743 8.5396 8.33588 8.41055 8.09834 8.38533C8.02796 8.37791 7.95612 8.38088 7.88574 8.38088C6.06026 8.38088 4.23479 8.3794 2.41077 8.38533C2.26268 8.38533 2.19964 8.33786 2.14832 8.20139C1.4929 6.46736 0.828695 4.73629 0.174749 3.00225C-0.361897 1.57972 0.531047 0.117134 1.99729 0.00588286C2.4489 -0.0282342 2.86531 0.0874672 3.25533 0.315903C7.12476 2.57505 10.9956 4.83271 14.8651 7.09185C16.1803 7.86022 16.3914 9.58388 15.2932 10.6059C15.1671 10.7246 15.0205 10.8254 14.8709 10.913C11.0015 13.1736 7.13209 15.4327 3.2612 17.6904C2.36239 18.211 1.33308 18.0805 0.523716 17.2572Z"
                                  fill="white"
                                />
                              </svg>
                            </button>
                          </div>
                        </>
                    }
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
