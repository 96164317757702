import React, { useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Modal, Row } from "react-bootstrap";
import Layout from "../Components/Layout";
import Slider from "react-slick";
import { Link, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import ContactUsComp from "../CommonComponent/ContactUsComp";

export default function DeliverWithUs() {
  return (
    <Layout>
      <section className="become-partner-sec">
        <Container>
          <Row className="align-items-end">
            <Col lg={5}>
              <div className="become-partner-banner new-partner">
                <div>
                  <p>Join the largest delivery platform!</p>
                  <h1>
                    Deliver with <span>Milcake</span>
                  </h1>
                </div>
                <div className="download-app">
                  <h2>Download Our App</h2>
                  <p>
                    Experience the Ultimate Convenience in Delightful Treats
                    with the Milcake App. Satisfy your sweet tooth with just a
                    few taps using the our app! Whether you’re craving a rich,
                    decadent cake for a special occasion, fresh and flaky
                    pastries for your morning coffee, or an assortment of other
                    delectable sweets, our app has you covered.
                  </p>

                  <button>Download App</button>
                </div>
              </div>
            </Col>
            <Col lg={7}>
              <div className="driver-with-us">
                <div className="new-card">
                  <span className="shape-ad-login">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="67"
                      height="67"
                      viewBox="0 0 67 67"
                      fill="none"
                    >
                      <rect
                        x="26.3418"
                        width="14.3162"
                        height="67"
                        fill="#A72A2F"
                      />
                      <rect
                        y="40.6582"
                        width="14.3162"
                        height="67"
                        transform="rotate(-90 0 40.6582)"
                        fill="#A72A2F"
                      />
                      <rect
                        x="4.58105"
                        y="15.8496"
                        width="14.3162"
                        height="67"
                        transform="rotate(-45 4.58105 15.8496)"
                        fill="#A72A2F"
                      />
                      <rect
                        x="51.957"
                        y="4.58203"
                        width="14.3162"
                        height="67"
                        transform="rotate(45 51.957 4.58203)"
                        fill="#A72A2F"
                      />
                    </svg>
                  </span>
                  <div className="login-cmn-box">
                    <div className="welcome-to-image">
                      <img
                        src={require("../Assets/images/welcome-image.png")}
                      />
                    </div>
                    <div className="address-section">
                      <div className="home-address">
                        <h2>Address</h2>
                        <div className="estimate-time">
                          <h4>
                            <span>
                              <img
                                src={
                                  require("../Assets/images/driver-location-icon.svg")
                                    .default
                                }
                              />
                            </span>
                            Your Home
                          </h4>
                          <p>mount hill road, near station</p>
                          <h4 className="mt-3">
                            <span>
                              <img
                                src={
                                  require("../Assets/images/time-icon-driver.svg")
                                    .default
                                }
                              />
                            </span>
                            Estimate Time
                          </h4>
                          <p>25 minutes</p>
                        </div>
                      </div>
                      <div className="birthday-image">
                        <img
                          src={
                            require("../Assets/images/birthday-image.svg")
                              .default
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="overview-count-sec">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="overview-count-contain">
                <div className="overview-count-item">
                  <h2>546+</h2>
                  <p>Registered Riders</p>
                </div>
                <div className="overview-count-line"></div>
                <div className="overview-count-item">
                  <h2>789,900+</h2>
                  <p>Orders Delivered</p>
                </div>
                <div className="overview-count-line"></div>
                <div className="overview-count-item">
                  <h2>690+</h2>
                  <p>Restaurants Partnered</p>
                </div>
                <div className="overview-count-line"></div>
                <div className="overview-count-item">
                  <h2>17,457+</h2>
                  <p>Food items</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="partner-restaurant-sec">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="partner-heading-text why-cake">
                <h2>
                  Why Delivery with <span> Milcake </span> ?
                </h2>
              </div>
              <div className="milcake-grid">
                <div className="deliver-your-way">
                  <img
                    src={require("../Assets/images/payout-icon.svg").default}
                  />
                  <h2>Regular Payout </h2>
                  <p>
                    we believe in rewarding your hard work every step of the
                    way. Enjoy regular payouts with exciting bonuses and
                    incentives
                  </p>
                  <div className="deliver-bottom-image">
                    <img src={require("../Assets/images/pngegg.svg").default} />
                  </div>
                </div>

                <div className="deliver-your-way">
                  <img
                    src={
                      require("../Assets/images/protection-icon.svg").default
                    }
                  />
                  <h2>Insurance Protection</h2>
                  <p>
                    Our comprehensive insurance coverage offers peace of mind,
                    ensuring you're protected from unexpected challenges.
                  </p>
                  <div className="deliver-bottom-image">
                    <img src={require("../Assets/images/bott-cake.png")} />
                  </div>
                </div>

                <div className="deliver-your-way">
                  <img
                    src={require("../Assets/images/flexible-icon.svg").default}
                  />
                  <h2>Flexible Hours </h2>
                  <p>
                    we prioritize your flexibility and reward your dedication.
                    Our flexible working hours empower you to manage your
                    schedule effectively.
                  </p>
                  <div className="deliver-bottom-image">
                    <img
                      src={
                        require("../Assets/images/customers-bott-icon.svg")
                          .default
                      }
                    />
                  </div>
                </div>

                <div className="deliver-your-way">
                  <img
                    src={require("../Assets/images/ultimate-icon.svg").default}
                  />
                  <h2>Your Ultimate Protection </h2>
                  <p>
                    Caring for your safety is our top priority. Our
                    comprehensive safety kit provides you with everything you
                    need for peace of mind.
                  </p>
                  <div className="deliver-bottom-image">
                    <img
                      src={
                        require("../Assets/images/growth-bott-icon.svg").default
                      }
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="partner-restaurant-sec">
        <Container>
          <Row>
            <Col lg={12}>
              <div className=" about-us-section">
                <div className="about-left-side-image">
                  <img src={require("../Assets/images/about-image.png")} />
                </div>
                <div className="about-disp-right-side">
                  <h2>
                    About <span>Us</span>
                  </h2>
                  <p>
                    we prioritize your flexibility and reward your dedication.
                    Our flexible working hours empower you to manage your
                    schedule effectively.Our comprehensive insurance coverage
                    offers peace of mind, ensuring you're protected from
                    unexpected challenges.
                  </p>
                  <button>Download App</button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="three-easy-steps phone-steps">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="easy-steps">
                <div className="cricle-image">
                  <img src={require("../Assets/images/cricle.png")} />
                </div>
                <div className="step-image">
                  <img src={require("../Assets/images/get-steps-image.png")} />
                </div>
                <div className="get-star-ted">
                  <h2>Get Started in 3 Easy Steps</h2>
                  <div className="steps-grid">
                    <div className="regi-ster-shop">
                      <img
                        src={
                          require("../Assets/images/downolad-application-icon.svg")
                            .default
                        }
                      />
                      <h4>Download Application</h4>
                      <p>
                        Download our app now to become a rider. Enjoy flexible
                        hours and competitive earnings.
                      </p>
                    </div>
                    <div className="regi-ster-shop left-right-line">
                      <img
                        src={
                          require("../Assets/images/fill-details-icon.svg")
                            .default
                        }
                      />
                      <h4>Fill Details</h4>
                      <p>
                        Congratulations on taking the first step! Now, it's time
                        to complete your registration.
                      </p>
                    </div>
                    <div className="regi-ster-shop  left-right-line">
                      <img
                        src={
                          require("../Assets/images/earn-money-icon.svg")
                            .default
                        }
                      />
                      <h4>Earn Money</h4>
                      <p>
                        Start earning today by completing rides with our milk
                        cake delivery service
                      </p>
                    </div>
                  </div>
                  <div className=" get-started-btn">
                    <button>Get Started</button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="phone-section">
                <div className="i-phone">
                  <img src={require("../Assets/images/phone-image.png")} />
                </div>
                <div className="ready-to-start">
                  <h2>Ready to get started with Milcake?</h2>
                  <div className="store-images">
                    <img
                      src={
                        require("../Assets/images/app-store-apple.svg").default
                      }
                    />
                    <img
                      src={
                        require("../Assets/images/app-store-google.svg").default
                      }
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="contact-us-sec">
                <div className="partner-heading-text contact-heading">
                  <h2>
                    Contact <span>Us</span>
                  </h2>
                </div>
                <div className="contact-form">
                  <div className="cont-dep">
                    <p>
                      Have questions or need assistance? Reach out to us! Our
                      team is here to help with any inquiries or support you may
                      need regarding our milk cake delivery service.
                    </p>
                  </div>
                  <div className="input-form login-box-inner-wrap">
                    <ContactUsComp />
                    <div className="contact-cake">
                      <img
                        src={
                          require("../Assets/images/contact-us-image.svg")
                            .default
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}
