import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import Slider from "react-slick";
import {
  productLikeAction,
  productListAction,
} from "../Redux/Action/HomeAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ImagePath } from "../utilis/ImageUrl";
import { Rating } from "react-simple-star-rating";
import ReactReadMoreReadLess from "react-read-more-read-less";

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToScroll: 1,
};

const ProductCard = ({ productData, productList }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [productLikes, setProductLikes] = useState({});
  const slider = React.useRef(null);

  const routeToDetails = (id) => {
    navigate(`/single-dish/${id}`);
  };

  useEffect(() => {
    if (productList?.data?.length > 0) {
      const initialLikes = productList.data.reduce((acc, res) => {
        res.data.forEach((product) => {
          acc[product._id] = product.is_product_liked;
        });
        return acc;
      }, {});
      setProductLikes(initialLikes);
    }
  }, [productList]);

  console.log("productLikes", productLikes);

  const productLikeApi = async (productId) => {
    let payload = {
      product_id: productId,
    };
    await dispatch(productLikeAction(payload)).then((res) => {
      if (res?.payload?.status === 200) {
        dispatch(productListAction());
        setProductLikes((prevLikes) => ({
          ...prevLikes,
          [productId]: res?.payload?.data?.is_product_liked,
        }));
      }
    });
  };

  return (
    <>
      {Array?.isArray(productData?.data) && productData?.data?.length > 0 ? (
        productData?.data.map((data) => {
          return (
            <>
              <Col
                lg={3}
                className="mb-4"
                style={{ cursor: "pointer" }}
                key={data?._id}
              >
                <div
                  className={`single-products-main cart-add ${
                    data?.is_added_to_cart && `active`
                  }`}
                >
                  <div onClick={() => routeToDetails(data?._id)}>
                    {data?.images?.length > 1 ? (
                      <>
                        <Slider {...settings} ref={slider}>
                          {data?.images.map((image, index) => {
                            return (
                              <>
                                <div key={index} className="position-relative">
                                  <button
                                    className={
                                      productLikes[data?._id]
                                        ? "active-heart like-btn"
                                        : "like-btn"
                                    }
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      productLikeApi(data?._id);
                                    }}
                                  >
                                    <svg
                                      width="20"
                                      height="18"
                                      viewBox="0 0 20 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M14.5445 0.75C11.5015 0.75 10.0015 3.75 10.0015 3.75C10.0015 3.75 8.50147 0.75 5.45747 0.75C2.98447 0.75 1.02647 2.82 1.00047 5.288C0.949469 10.413 5.06647 14.058 9.57947 17.12C9.7038 17.2045 9.85065 17.2496 10.001 17.2496C10.1513 17.2496 10.2981 17.2045 10.4225 17.12C14.9345 14.057 19.0525 10.413 19.0005 5.288C18.9755 2.819 17.0175 0.75 14.5445 0.75Z"
                                        stroke="#A72A2F"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </button>
                                  <img
                                    className="main-image"
                                    src={
                                      ImagePath(image) ||
                                      require("../Assets/images/chesecale.png")
                                    }
                                  />
                                </div>
                              </>
                            );
                          })}
                        </Slider>
                      </>
                    ) : (
                      <>
                        <div className="position-relative">
                          <button
                            className={
                              productLikes[data?._id]
                                ? "active-heart like-btn"
                                : "like-btn"
                            }
                            onClick={(event) => {
                              event.stopPropagation();
                              productLikeApi(data?._id);
                            }}
                          >
                            <svg
                              width="20"
                              height="18"
                              viewBox="0 0 20 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.5445 0.75C11.5015 0.75 10.0015 3.75 10.0015 3.75C10.0015 3.75 8.50147 0.75 5.45747 0.75C2.98447 0.75 1.02647 2.82 1.00047 5.288C0.949469 10.413 5.06647 14.058 9.57947 17.12C9.7038 17.2045 9.85065 17.2496 10.001 17.2496C10.1513 17.2496 10.2981 17.2045 10.4225 17.12C14.9345 14.057 19.0525 10.413 19.0005 5.288C18.9755 2.819 17.0175 0.75 14.5445 0.75Z"
                                stroke="#A72A2F"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                          <img
                            className="main-image"
                            src={
                              ImagePath(data?.images[0]) ||
                              require("../Assets/images/chesecale.png")
                            }
                          />
                        </div>
                      </>
                    )}

                    <div className="d-flex justify-content-between">
                      <Rating
                        disableFillHover={true}
                        readonly={true}
                        initialValue={data?.avg_ratings ?? 0}
                      />
                      <img
                        src={
                          require("../Assets/images/arrow-right.svg").default
                        }
                      />
                    </div>
                  </div>

                  <div className="" style={{ marginLeft: "5px" }}>
                    <h3>{data?.name || "N/A"}</h3>

                    <ReactReadMoreReadLess
                      charLimit={100}
                      readMoreText={"Read more "}
                      readLessText={"Read less "}
                      readMoreClassName="read-more-less--more"
                      readLessClassName="read-more-less--less"
                    >
                      {data?.description || "N/A"}
                    </ReactReadMoreReadLess>
                    <div className="product-bottom">
                      <div className="d-flex gap-2">
                        <h4
                          className={data?.good_to_go === 1 && `strikethrough`}
                        >
                          {`$${data?.price || "0"}`}{" "}
                        </h4>
                        <h4>
                          {data?.good_to_go === 1 &&
                            `$${data?.good_to_go_price}`}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </>
          );
        })
      ) : (
        <p> No Product Availaable</p>
      )}
    </>
  );
};

export default ProductCard;
