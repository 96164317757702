import React, { useState } from "react";
import { Col, Container, Form, InputGroup, Modal, Row } from "react-bootstrap";
import Layout from "../Components/Layout";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import _ from "lodash";
import { useFormik } from "formik";
import { shopLoginAction } from "../Redux/Action/AuthAction";
import * as Yup from "yup";
import ContactUsComp from "../CommonComponent/ContactUsComp";

export default function BecomePartner() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),

    password: Yup.string()
      .min(6, 'Password must be at least 6 characters long')
      .required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log("values", values)
      let userData = {
        role: 3,
        email: values?.email,
        password: values.password,
      };
      setIsLoader(true);
      await dispatch(shopLoginAction(userData)).then((res) => {
        console.log("res", res);
        if (res?.payload?.status === 200) {
          if (res?.payload?.data?.token) {
            window.open(`https://milcake-shop.bosselt.com/Dashboard?token=${res?.payload?.data?.token}`, '_blank');
          } else {
            toast.error("Token not Found, Please Login again")
          }
        } else {
          toast.error(res?.payload?.message);
        }
        setIsLoader(false);
        formik.resetForm();
      });
    },
  });


  return (
    <Layout>
      <section className="become-partner-sec">
        <Container>
          <Row className="align-items-end">
            <Col lg={6}>
              <div className="become-partner-banner">
                <p>Join the largest bakery network!</p>
                <h1>
                  Unleash New Profit Opportunities with <span>Milcake</span>
                </h1>
                <img
                  src={require("../Assets/images/banner-right-bt.png")}
                  className="w-100"
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="become-partner-login">
                <div className="card-box-set">
                  <span className="shape-ad-login">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="67"
                      height="67"
                      viewBox="0 0 67 67"
                      fill="none"
                    >
                      <rect
                        x="26.3418"
                        width="14.3162"
                        height="67"
                        fill="#A72A2F"
                      />
                      <rect
                        y="40.6582"
                        width="14.3162"
                        height="67"
                        transform="rotate(-90 0 40.6582)"
                        fill="#A72A2F"
                      />
                      <rect
                        x="4.58105"
                        y="15.8496"
                        width="14.3162"
                        height="67"
                        transform="rotate(-45 4.58105 15.8496)"
                        fill="#A72A2F"
                      />
                      <rect
                        x="51.957"
                        y="4.58203"
                        width="14.3162"
                        height="67"
                        transform="rotate(45 51.957 4.58203)"
                        fill="#A72A2F"
                      />
                    </svg>
                  </span>
                  <div className="login-cmn-box">
                    <div className="login-box-inner-wrap">
                      <h2 className="text-center">Login to Account</h2>
                      <p className="text-center">
                        Please enter your email & password to continue
                      </p>
                      <Form onSubmit={formik.handleSubmit}>
                        <div className="form-set">
                          <Form.Label>Email</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="14"
                                viewBox="0 0 18 14"
                                fill="none"
                              >
                                <path
                                  d="M8.99152 13.9951C7.07519 13.9951 5.15886 14.0087 3.24309 13.9906C1.70014 13.9759 0.268368 12.6922 0.0454608 11.163C0.0201942 10.9887 0.00166537 10.8115 0.00166537 10.636C-0.00114203 8.18593 -1.90675e-05 5.73642 0.00222685 3.28691C0.00278833 2.81461 0.129121 2.36996 0.328447 1.94563C0.434005 1.72161 0.494083 1.71146 0.667019 1.88469C1.19313 2.41172 1.71811 2.94045 2.24366 3.46804C3.49295 4.72299 4.72933 5.99091 5.99546 7.22836C7.66362 8.85911 10.3116 8.87604 11.9803 7.24472C13.7551 5.50902 15.4895 3.73156 17.2419 1.97215C17.279 1.93491 17.3149 1.8971 17.3525 1.86099C17.4957 1.72444 17.5597 1.73177 17.6462 1.90444C17.8539 2.31805 17.9898 2.75706 17.9915 3.22033C17.9982 5.72909 18.0072 8.23785 17.9904 10.746C17.9797 12.2995 16.6821 13.7418 15.1476 13.9517C14.9163 13.9833 14.6804 13.9957 14.4469 13.9963C12.6282 13.9996 10.8102 13.9979 8.99152 13.9979C8.99152 13.9979 8.99152 13.9968 8.99152 13.9951Z"
                                  fill="#8391A1"
                                />
                                <path
                                  d="M9.00051 0.00114491C10.9107 0.00114491 12.8203 -0.00280441 14.7304 0.00396687C15.3581 0.00622397 15.9309 0.212183 16.4547 0.554697C16.6316 0.670373 16.6366 0.720029 16.4867 0.871254C15.6995 1.66462 14.9112 2.4563 14.1235 3.24854C13.151 4.22642 12.1796 5.20431 11.2054 6.18107C9.93313 7.45745 8.0634 7.45689 6.7894 6.17768C5.03646 4.41828 3.28632 2.65661 1.53563 0.896082C1.35034 0.709871 1.35371 0.674324 1.5783 0.532127C2.10104 0.200334 2.66926 0.00452962 3.29025 0.0028368C5.19367 -0.00224166 7.09709 0.00114491 9.00051 0.00114491Z"
                                  fill="#8391A1"
                                />
                              </svg>
                            </InputGroup.Text>
                            <Form.Control
                              className="border-right-o"
                              name="email"
                              placeholder="Email Address"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </InputGroup>
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger mb-2">
                              {formik.errors.email}
                            </div>
                          ) : null}

                          <Form.Label>Password</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="17"
                                viewBox="0 0 14 17"
                                fill="none"
                              >
                                <path
                                  d="M6.52621 0C6.84228 0 7.15835 0 7.47391 0C7.59105 0.0220721 7.70718 0.0482827 7.82532 0.0657565C9.96509 0.384422 11.6499 2.1718 11.6646 4.14358C11.6696 4.82413 11.6656 5.50469 11.6661 6.18525C11.6661 6.2441 11.6661 6.30342 11.6661 6.37562C11.8554 6.37562 12.0241 6.37562 12.1932 6.37562C13.2439 6.377 13.9987 7.06031 13.9992 8.01355C14.0003 10.4594 14.0003 12.9053 13.9992 15.3511C13.9987 16.3163 13.2474 16.9997 12.1861 16.9997C8.72909 17.0001 5.27204 17.0001 1.81449 16.9997C0.75369 16.9997 0.000883575 16.3154 0.000378675 15.3516C-0.000126225 12.9113 -0.000126225 10.4709 0.000378675 8.03056C0.000378675 7.05617 0.750661 6.37608 1.82458 6.37562C1.99171 6.37562 2.15933 6.37562 2.33352 6.37562C2.33352 5.65919 2.32696 4.9676 2.33706 4.27647C2.34059 4.01804 2.35321 3.75456 2.41027 3.50211C2.79399 1.80393 3.90376 0.692052 5.71585 0.16692C5.9789 0.0910474 6.25559 0.0547204 6.52621 0ZM10.1039 6.37056C10.1039 5.65322 10.106 4.95151 10.1024 4.25026C10.1019 4.10725 10.0878 3.96332 10.0671 3.82169C9.85401 2.35942 8.28781 1.25535 6.6797 1.43147C5.062 1.6085 3.90275 2.78982 3.89619 4.27003C3.89366 4.91702 3.89568 5.56401 3.89568 6.211C3.89568 6.26342 3.89568 6.31538 3.89568 6.37056C5.97587 6.37056 8.02678 6.37056 10.1039 6.37056ZM7.00183 9.21372C6.33031 9.21234 5.74109 9.60044 5.52651 10.1854C5.31748 10.7555 5.53459 11.3915 6.07736 11.7483C6.19702 11.827 6.23388 11.9065 6.23186 12.0339C6.22428 12.4983 6.22731 12.9632 6.22933 13.4277C6.23135 13.8571 6.56055 14.1657 7.00688 14.1611C7.44715 14.1565 7.76776 13.8525 7.76978 13.4309C7.77231 12.9554 7.77382 12.4795 7.76776 12.004C7.76625 11.8927 7.80412 11.8265 7.9051 11.7607C8.45998 11.3998 8.68466 10.7661 8.47412 10.1886C8.26055 9.60228 7.67638 9.2151 7.00183 9.21372Z"
                                  fill="#8391A1"
                                />
                              </svg>
                            </InputGroup.Text>
                            <Form.Control
                              className="border-right-o"
                              placeholder="Password"
                              type={showPassword ? "text" : "password"}
                              name="password"
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />

                            <button
                              type="button"
                              className="pass-show"
                              onClick={() => setShowPassword((prev) => !prev)}
                            >
                              {showPassword ? (
                                <img
                                  src={
                                    require("../Assets/images/hide_password.svg")
                                      .default
                                  }
                                  alt="hide password"
                                />
                              ) : (
                                <img
                                  src={require("../Assets/images/eye.svg").default}
                                  alt="show password"
                                />
                              )}
                            </button>
                          </InputGroup>
                          {formik.touched.password && formik.errors.password ? (
                            <div className="text-danger">
                              {formik.errors.password}
                            </div>
                          ) : null}
                        </div>

                        <button
                          variant="primary"
                          type="submit"
                          className="submit"
                        >
                          {isLoader &&
                            <>
                              <div className="spinner-border spinner-border-sm" role="status" >
                              </div>
                            </>
                          } {" "}
                          Login
                        </button>

                        <div className="text-center mt-5 login-bottom-line">
                          <p>
                            Don’t have an account?
                            <a
                              href="https://milcake-shop.bosselt.com/CreateAccount"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Register Now
                            </a>
                          </p>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="overview-count-sec">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="overview-count-contain">
                <div className="overview-count-item">
                  <h2>546+</h2>
                  <p>Registered Riders</p>
                </div>
                <div className="overview-count-line"></div>
                <div className="overview-count-item">
                  <h2>789,900+</h2>
                  <p>Orders Delivered</p>
                </div>
                <div className="overview-count-line"></div>
                <div className="overview-count-item">
                  <h2>690+</h2>
                  <p>Restaurants Partnered</p>
                </div>
                <div className="overview-count-line"></div>
                <div className="overview-count-item">
                  <h2>17,457+</h2>
                  <p>Food items</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="partner-restaurant-sec">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="partner-heading-text">
                <h2>
                  Become a <span> Partner Restaurant </span> to get Exciting
                  offers
                </h2>
              </div>
              <div className="restaurant-section">
                <div className="restaurant-left-side">
                  <img
                    src={require("../Assets/images/restaurant-left-img.png")}
                  />
                </div>
                <div className="you-receive-section">
                  <div className="visibility-right">
                    <h2>You Receive </h2>
                    <div className="increased-text">
                      <img
                        src={require("../Assets/images/red-right-icon.png")}
                      />
                      <div className="text-disp">
                        <h3>Increased Visibility</h3>
                        <p>
                          Boost your business with enhanced visibility on our
                          platform. Reach more customers and expand your
                          presence in the dessert-loving community."
                        </p>
                      </div>
                    </div>

                    <div className="increased-text">
                      <img
                        src={require("../Assets/images/red-right-icon.png")}
                      />
                      <div className="text-disp">
                        <h3>Reduced delivery commission </h3>
                        <p>
                          Enjoy lower delivery commissions when you partner with
                          us. Maximize your profits and keep more of what you
                          earn from each order."
                        </p>
                      </div>
                    </div>

                    <div className="increased-text">
                      <img
                        src={require("../Assets/images/red-right-icon.png")}
                      />
                      <div className="text-disp">
                        <h3>Sponsorship opportunities</h3>
                        <p>
                          Unlock sponsorship opportunities to further promote
                          your offerings. Collaborate with brands and enhance
                          your market presence through strategic partnerships."
                        </p>
                      </div>
                    </div>
                    <div className="increased-text">
                      <img
                        src={require("../Assets/images/red-right-icon.png")}
                      />
                      <div className="text-disp">
                        <h3>More Orders</h3>
                        <p>
                          "Drive growth with a steady influx of orders. Tap into
                          our network of customers eager to indulge in your
                          delicious desserts."
                        </p>
                      </div>
                    </div>
                    <div className="bott-image">
                      <img src={require("../Assets/images/bott-cake.png")} />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="partner-restaurant-sec">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="partner-heading-text why-cake">
                <h2>
                  Why <span> Milcake </span> ?
                </h2>
              </div>
              <div className="milcake-grid">
                <div className="deliver-your-way">
                  <img
                    src={require("../Assets/images/deliver-icon.svg").default}
                  />
                  <h2>Deliver your way </h2>
                  <p>
                    Our offerings are flexible so you can customize them to your
                    needs. Get started with your delivery people through the
                    Milcake platform.
                  </p>
                  <div className="deliver-bottom-image">
                    <img src={require("../Assets/images/pngegg.svg").default} />
                  </div>
                </div>

                <div className="deliver-your-way">
                  <img src={require("../Assets/images/boost.svg").default} />
                  <h2>Boost your visibility </h2>
                  <p>
                    Stand out with in-app marketing to reach even more customers
                    and increase sales.
                  </p>
                  <div className="deliver-bottom-image">
                    <img src={require("../Assets/images/bott-cake.png")} />
                  </div>
                </div>

                <div className="deliver-your-way">
                  <img
                    src={require("../Assets/images/customers-icon.svg").default}
                  />
                  <h2>Connect with customers </h2>
                  <p>
                    Our offerings are flexible so you can customize them to your
                    needs. Get started with your delivery people through the
                    Milcake platform.
                  </p>
                  <div className="deliver-bottom-image">
                    <img
                      src={
                        require("../Assets/images/customers-bott-icon.svg")
                          .default
                      }
                    />
                  </div>
                </div>

                <div className="deliver-your-way">
                  <img
                    src={require("../Assets/images/growth-icon.svg").default}
                  />
                  <h2>Unlock new growth </h2>
                  <p>
                    Connect with thousands of dessert lovers in your area by
                    partnering with our milcake platform.
                  </p>
                  <div className="deliver-bottom-image">
                    <img
                      src={
                        require("../Assets/images/growth-bott-icon.svg").default
                      }
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="partner-restaurant-sec">
        <Container>
          <Row>
            <Col lg={12}>
              <div className=" about-us-section">
                <div className="about-left-side-image">
                  <img src={require("../Assets/images/about-image.png")} />
                </div>
                <div className="about-disp-right-side">
                  <h2>
                    About <span>Us</span>
                  </h2>
                  <p>
                    we prioritize your flexibility and reward your dedication.
                    Our flexible working hours empower you to manage your
                    schedule effectively.Our comprehensive insurance coverage
                    offers peace of mind, ensuring you're protected from
                    unexpected challenges.
                  </p>
                  <button>Download App</button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="three-easy-steps">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="easy-steps">
                <div className="cricle-image">
                  <img src={require("../Assets/images/cricle.png")} />
                </div>
                <div className="step-image">
                  <img src={require("../Assets/images/get-steps-image.png")} />
                </div>
                <div className="get-star-ted">
                  <h2>Get Started in 3 Easy Steps</h2>
                  <div className="steps-grid">
                    <div className="regi-ster-shop">
                      <img
                        src={
                          require("../Assets/images/register-shop.svg").default
                        }
                      />
                      <h4>Register your Shop</h4>
                      <p>
                        Download our app now to become a rider. Enjoy flexible
                        hours and competitive earnings.
                      </p>
                    </div>
                    <div className="regi-ster-shop left-right-line">
                      <img
                        src={
                          require("../Assets/images/upload-menu.svg").default
                        }
                      />
                      <h4>Upload your menu</h4>
                      <p>
                        Congratulations on taking the first step! Now, it's time
                        to complete your registration.
                      </p>
                    </div>
                    <div className="regi-ster-shop  left-right-line">
                      <img
                        src={
                          require("../Assets/images/dashboard-icon.svg").default
                        }
                      />
                      <h4>Access Restaurant Dashboard and go live!</h4>
                      <p>
                        Start earning today by completing rides with our milk
                        cake delivery service
                      </p>
                    </div>
                  </div>
                  <div className=" get-started-btn">
                    <button>Get Started</button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="lep-top-section">
                <img src={require("../Assets/images/leptop.png")} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="contact-us-sec">
                <div className="partner-heading-text contact-heading">
                  <h2>
                    Contact <span>Us</span>
                  </h2>
                </div>
                <div className="contact-form">
                  <div className="cont-dep">
                    <p>
                      Have questions or need assistance? Reach out to us! Our
                      team is here to help with any inquiries or support you may
                      need regarding our milk cake delivery service.
                    </p>
                  </div>
                  <div className="input-form login-box-inner-wrap">
                    <ContactUsComp />
                    
                    <div className="contact-cake">
                      <img
                        src={
                          require("../Assets/images/contact-us-image.svg")
                            .default
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}
