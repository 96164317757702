import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

const InviteFriendModal = ({ showInvite, setShowInvite }) => {
    const [inviteLink, setInviteLink] = useState('');
    const [copyStatus, setCopyStatus] = useState('Copy Link');

    useEffect(() => {
        setInviteLink(window.location.href);
    }, []);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(inviteLink)
            .then(() => {
                setCopyStatus('Copied'); 
                setTimeout(() => setCopyStatus('Copy Link'), 5000);
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };

    return (
        <Modal
            size="lg"
            className="cmn-modal cmn-modal-invite"
            show={showInvite}
            onHide={() => {
                setShowInvite(false);
            }}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <h2>Invite your friend to Share the delight</h2>
                <div className='invite-url'>
                    <input type="text" value={inviteLink} readOnly />
                    <button onClick={copyToClipboard}>{copyStatus}</button>
                </div>
                <p>With reliable delivery and outstanding customer support, we make sure every meal is just as enjoyable as if you made it yourself. Let us handle the details while you enjoy the flavors!</p>
            </Modal.Body>
        </Modal>
    );
};

export default InviteFriendModal;
