import { createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
  name: "loader",
  initialState: { 
    value: false,
  },
  reducers: {
    setLoader: (state, action) => {
      state.value = action.payload; 
    },
  },
});

export const { setLoader } = loaderSlice.actions; 
export default loaderSlice.reducer;
