import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Decryptedid, Encryptedid } from "../utilis/BcryptEcryptId";
import { toast } from "react-toastify";
import { ForgotPasswordAction, forgotOtpVerifyAction, userOtpVerification, userResendOtpVerification } from "../Redux/Action/AuthAction";
import OtpInput from "react-otp-input";
import { useQuery } from "../utilis/commonFunction";

export default function OtpVerification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const query = useQuery();
  const phoneFromQuery = Decryptedid(atob((query.get("phone"))));
  const countryCodeFromQuery = Decryptedid(atob(query.get("countrycode")));
  const otp_fill = query.get("otp");

  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(59);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);


  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const handleVerify = () => {
    const verifyObj = {
      role: 1,
      phone_no: phoneFromQuery,
      country_code: countryCodeFromQuery,
      otp: otp,
    };

    if (otp.length === 4) {
      if (timer === 0) {
        setError("Your OTP has been expired. Please resend OTP");
      } else {
        setIsLoader(true);
        dispatch(forgotOtpVerifyAction(verifyObj)).then((response) => {
          console.log(response, "otppppp", otp);
          if (response?.payload?.status === 200) {
            navigate(`/reset-password/${Encryptedid(response?.payload?.data?._id)}`)
            toast.success(response?.payload?.message);
          } else {
            toast.error(response?.payload?.message);
          }
          setIsLoader(false);
        });
      }
    } else if (otp.length > 1 && otp.length < 4) {
      setError("Please enter a 4-digit OTP.");
    } else if (timer === 0 && otp.length === 0) {
      setError("Your OTP has been expired. Please resend OTP");
    } else {
      setError("Please enter a 4-digit OTP.");
    }
  };

  const handleResendFunc = () => {
    setLoading(true);
    const obj = {
      role: 1,
      phone_no: phoneFromQuery,
      country_code: countryCodeFromQuery,
    };
    dispatch(ForgotPasswordAction(obj))
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success(`${res?.payload?.message} OTP is ${res?.payload?.data?.otp}`);
          setOtp("")
          setError("");
          setTimer(60);
        }
      })
      .catch((error) => {
        console.error("Error resending OTP:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  useEffect(() => {
    if (otp) {
      setError("");
    }
  }, [otp]);

  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col
              lg={6}
              className="px-0 d-flex align-items-center  justify-content-center"
            >
              <div className="cmn-form">
                <h2 className="heading-30">OTP Verification</h2>
                <p className="sub-16 text-center">
                  Please enter otp for verification
                </p>

                <Form>
                  <div className="form-set height-none justify-content-add otp-outline-chnge opt-verify-fields">

                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      separator={<span style={{ width: "8px" }}></span>}
                      renderInput={(props) => <input {...props} />}
                      Style={{
                        border: "1px solid transparent",
                        borderRadius: "8px",
                        width: "100px",
                        height: "54px",
                        fontSize: "12px",
                        color: "#000",
                        fontWeight: "400",
                        caretColor: "blue",
                        borderBlock: "1px solid var(--Border, #E8ECF4)",
                        justifyContent: "space-between",
                      }}
                    />
                  </div>

                  <div className="mt-5">
                    {error && <p className="text-danger">{error}</p>}
                    <button
                      variant="primary"
                      type="button"
                      className="cmn-red-btn mb-0"
                      onClick={handleVerify}
                      disabled={isLoader ? true : false}
                    >
                      {isLoader &&
                        <>
                          <div class="spinner-border spinner-border-sm" role="status" >
                          </div>{" "}
                        </>
                      }
                      Verify
                    </button>
                  </div>

                  <div className="mt-3 text-center">
                    {timer > 0 ? (
                      <p>Resend OTP in {formatTime(timer)} seconds</p>
                    ) : (
                      <button
                        className="btn btn-link resend-btn-txt"
                        onClick={handleResendFunc}
                        disabled={loading}
                      >
                        {loading ? "Sending..." : "Resend OTP"}
                      </button>
                    )}
                  </div>
                </Form>
              </div>
            </Col>
            <Col lg={6} className="px-0">
              <div className="left-img">
                <img src={require("../Assets/images/leftimg.jpeg")} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
