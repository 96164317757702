import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../utilis/commonFunction";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { userEditProfile } from "../Redux/Action/AuthAction";

export default function AllowLocation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const token = query.get("token");
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    const locationAdded = localStorage.getItem('isLocationAdded');
    if (locationAdded) {
      navigate(`/create-profile?token=${token}`, { replace: true })
    }
  }, [navigate]);


  const handleAllowLocation = () => {
    setIsLoader(true);
    if (navigator?.geolocation) {
      navigator?.geolocation?.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("latitude, longitude ", latitude, longitude)
          if (latitude && longitude) {
            allowLocation(latitude, longitude)
          }
        },
        (error) => {
          toast.error(error.message)
        }
      );
    } else {
      toast.error('Geolocation is not supported by this browser.')
      setIsLoader(false);
    }
  };


  const allowLocation = async (latitude, longitude) => {
    let formData = new FormData();
    formData.append("lat", latitude)
    formData.append("lng", longitude)
    formData.append("allow_location", 1);
    await dispatch(userEditProfile({ token, formData })).then((res) => {
      console.log("useEdit", res);
      if (res?.payload?.status === 200) {
        toast.success("Location Allow Successfully");
        navigate(`/create-profile?token=${token}`, { replace: true })
        localStorage.setItem("isLocationAdded", 1);
      } else {
        toast.error(res?.payload?.message);
      }
      setIsLoader(false);
    })
  }

  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col
              lg={6}
              className="px-0 d-flex align-items-center  justify-content-center"
            >
              <div className="cmn-form">
                <h2 className="heading-30">Allow Location</h2>
                <p className="sub-16 text-center">
                  Your Location details will help us get in <br />
                  touch with you
                </p>
                <img
                  className="sticker-img mt-4 pt-4"
                  src={require("../Assets/images/allowlocation.svg").default}
                />
                <button type="submit" className="cmn-red-btn mb-0" disabled={isLoader ? true : false} onClick={handleAllowLocation}>
                  {isLoader &&
                    <>
                      <div class="spinner-border spinner-border-sm" role="status" >
                      </div>{" "}
                    </>
                  }
                  Allow
                </button>
              </div>
            </Col>
            <Col lg={6} className="px-0">
              <div className="left-img">
                <img src={require("../Assets/images/leftimg.jpeg")} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
