import React from "react";
import { useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";

const Loader = () => {
  const loading = useSelector((state) => state.loader.value);
  console.log("loading", loading);

  return (
    <>
      {loading && (
        <div className="loader-style container-fluid">
          <FadeLoader
            className="fade-loader"
            loading={true}
            color={"#a72a2f"}
          />
        </div>
      )}
    </>
  );
};

export default Loader;
