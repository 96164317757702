import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Googlemaps from "../Components/Googlemaps";
import {
  addAddressAction,
  getListAddressAction,
} from "../Redux/Action/AddresAction";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const AddNewAddressModal = ({ showNewAddress, handleCloseNewAddress }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [address, setAddress] = useState({
    label: '',
    lat: '',
    lng: '',
    value: {
      description: '',
      place_id: '',
    },
  });

  const formik = useFormik({
    initialValues: {
      flat_no: "",
      landmark: "",
      type: "0",
    },
    validationSchema: Yup.object({
      flat_no: Yup.string().required("House/Flat Number is required"),
      landmark: Yup.string(),
      type: Yup.string().required("Please select an address type"),
    }),
    onSubmit: async (values) => {
      console.log("Form data", values);
      if (!address?.label) {
        return toast.warn("Please Add Location");
      }
      let payload = {
        flat_no: values?.flat_no,
        full_address: address?.label,
        landmark: values?.landmark,
        type: Number(values?.type),
        place_id: address?.value?.place_id,
        lat: address?.lat,
        lng: address?.lng,
      };
      await dispatch(addAddressAction(payload)).then((res) => {
        console.log("address userEdit res", res);
        if (res?.payload?.status === 200) {
          dispatch(getListAddressAction());
          toast.success(res?.payload?.message);
        } else {
          toast.error(res?.payload?.message);
        }
        formik.resetForm();
        setAddress("");
        handleCloseNewAddress();
      });
    },
  });

  console.log("address", address)

  return (
    <>
      <Modal
        className="cmn-modal"
        show={showNewAddress}
        onHide={handleCloseNewAddress}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="cmn-form-fields px-4 mt-2">
            <div className="address-main-sec">
              <div className="d-flex justify-content-between mb-3">
                <h2 className="heading-25">{t("my_address_modal.heading_add")}</h2>
              </div>
              <div className="newaddress-main">
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <div className="new-ad-map-box">
                        <Googlemaps
                          setAddress={setAddress}
                          address={address}
                          type={"emptyField"}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="address-fields">
                        <Form.Group className="mb-1 mt-3">
                          <Form.Control
                            className="px-3"
                            type="text"
                            placeholder={t("my_address_modal.placholder.house_no")}
                            name="flat_no"
                            value={formik.values.flat_no}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.flat_no && formik.errors.flat_no && (
                            <div className="text-danger">
                              {formik.errors.flat_no}
                            </div>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-1 mt-3">
                          <Form.Control
                            className="px-3"
                            type="text"
                            placeholder={t("my_address_modal.placholder.landmark")}
                            name="landmark"
                            value={formik.values.landmark}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={
                              formik.touched.landmark && formik.errors.landmark
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.landmark}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <div className="address-select">
                          <h3>{t("my_address_modal.save_as")}</h3>
                          <div className="d-flex">
                            <div className="select-address-box">
                              <input
                                type="radio"
                                name="type"
                                value={"0"}
                                checked={formik.values.type === "0"}
                                onChange={formik.handleChange}
                              />
                              <button type="button">{t("common-text.address-type.home")}</button>
                            </div>
                            <div className="select-address-box ms-3">
                              <input
                                type="radio"
                                name="type"
                                value={"1"}
                                checked={formik.values.type === "1"}
                                onChange={formik.handleChange}
                              />
                              <button type="button">{t("common-text.address-type.other")}</button>
                            </div>
                            <div className="select-address-box ms-3">
                              <input
                                type="radio"
                                name="type"
                                value={"2"}
                                checked={formik.values.type === "2"}
                                onChange={formik.handleChange}
                              />
                              <button type="button">{t("common-text.address-type.office")}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <button type="submit" className="cmn-red-btn mb-0 w-100">
                        {t("my_address_modal.btn.save_proceed")}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewAddressModal;
