import React from "react";
import { ImagePath } from "../utilis/ImageUrl";
import Icons from "../Assets/Icons";

const ProductDetailsDesCard = ({ data }) => {
  return (
    <>
      <p>{data?.description || "N/A"}</p>
      {data?.out_of_stock == 1 && <p>Product is Out of Stock</p>}
      <h5>
        *It will take {data?.days_for_prepration || "0"} days for preparation.
      </h5>
      <h6>
        Allergens: <span>{data?.allergens || "N/A"}</span>
      </h6>
      <div className="additions mt-2 mb-4">
        <ul>
          {data?.flavor_id.map((res) => {
            return (
              <li>
                {/* <img src={res?.image ? ImagePath(res?.image) : require("../Assets/images/strawberry.png")} alt="strawberry" /> */}
                {res?.name}
              </li>
            );
          })}
          {data?.dietary != 0 && (
            <li>
              {data?.dietary === 1 ? (
                <>{Icons.Vegetarian}</>
              ) : data?.dietary === 2 ? (
                <>{Icons.Vegan}</>
              ) : data?.dietary === 3 ? (
                <>{Icons.GlutenFree}</>
              ) : data?.dietary === 4 ? (
                <>{Icons.SugarFree}</>
              ) : (
                ""
              )}
              {data?.dietary === 1
                ? `Vegetarian`
                : data?.dietary === 2
                ? `Vegan`
                : data?.dietary === 3
                ? `Gluten-free`
                : `Sugar-free`}
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default ProductDetailsDesCard;
