import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../src/Locales/en.json';
import fr from '../src/Locales/fr.json';
import nl from '../src/Locales/nl.json';
import de from '../src/Locales/de.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en,
            },
            fr: {
                translation: fr,
            },
            nl: {
                translation: nl,
            },
            de: {
                translation: de,
            },
        },
        lng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
