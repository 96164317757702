import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Layout from "../Components/Layout";
import { Link } from "react-router-dom";
import useAuthToken from "../Hook/useAuthToken";
import BrowseCategoryHome from "../CommonComponent/BrowseCategoryHome";
import { useDispatch, useSelector } from "react-redux";
import {
  dealofdayAction,
  listShopAction,
  prepareOrderAction,
} from "../Redux/Action/HomeAction";
import { toast } from "react-toastify";
import AllowLocationModal from "../Modals/allowLocationModal";
import HomeShopList from "../CommonComponent/HomeShopList";
import { CategoryName, HomeTitles } from "../utilis/enums";
import { ImagePath } from "../utilis/ImageUrl";
import { useQuery } from "../utilis/commonFunction";
import _ from "lodash";

export default function Home() {
  const query = useQuery();
  const dispatch = useDispatch();
  const tokenData = useAuthToken();
  const { shopList, dealOfDayList, prepareOrderData } = useSelector(
    (state) => state.home
  );
  const [categoryId, setCategoryId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const rating = query.get("rating");
  const distance = query.get("distance")?.split(",").map(Number);
  const isGoodToGo = query.get("goodToGo") === "true";
  const [filterState, setFilterState] = useState();

  console.log("categoryId", categoryId);

  const handleAllowLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("latitude, longitude ", latitude, longitude);
          if (latitude && longitude) {
            // dispatch(listShopAction({ lat: latitude, lng: longitude }));
            dealOfDayApiCall(latitude, longitude);
          }
        },
        (error) => {
          toast.error(error.message);
          // setIsModalOpen(true)
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    let query;
    if (rating || isGoodToGo || distance) {
      query = {
        min_distance: distance?.length > 0 ? distance[0] : "",
        max_distance: distance?.length > 0 ? distance[1] : "",
        good_to_go: isGoodToGo === true ? 1 : "",
        // min_rating: 0,
        max_rating: rating ?? "",
      };
      ListShopApiCall(query);
    } else {
      query = {};
      ListShopApiCall(query);
    }
  }, [categoryId, rating, isGoodToGo, distance, filterState, dispatch]);

  const ListShopApiCall = (query) => {
    if (categoryId === CategoryName.MOSTPOPULAR) {
      query = { ...query, list_by: 2 };
    } else if (categoryId === CategoryName.FAVOURITE) {
      query = { ...query, list_by: 1 };
    } else if (categoryId === CategoryName.GOODTOGO) {
      query = { ...query, good_to_go: 1 };
    } else if (categoryId === CategoryName.ALLDELIGHT) {
      query = { ...query, category_id: "" };
    } else if (categoryId) {
      query = { ...query, category_id: categoryId };
    }

    if (!_.isEqual(filterState, query)) {
      setFilterState(query);
      dispatch(listShopAction(query));
    }
  };

  const dealOfDayApiCall = async (lat, lng) => {
    await dispatch(dealofdayAction({ lat, lng }));
  };

  useEffect(() => {
    handleAllowLocation();
  }, []);

  useEffect(() => {
    let islocation = localStorage.getItem("isLocationAdded");
    let isProfle = localStorage.getItem("isProfile");
    if (isProfle || islocation) {
      localStorage.clear();
      window.location.reload();
    }
  }, []);

  const getPrepareOrderApiCall = async () => {
    await dispatch(prepareOrderAction());
  };

  useEffect(() => {
    if (tokenData) {
      getPrepareOrderApiCall();
    }
  }, []);

  return (
    <Layout>
      <Container>
        {dealOfDayList?.data?.length > 0 && (
          <section className="pt-4">
            <div className="deals-sec">
              <div className="deal-cmn-heading mb-2">
                <h2 className="heading-25">Deals of the Day</h2>
                <span></span>
              </div>
              <div className="deals-cards">
                <Row>
                  {Array.isArray(dealOfDayList?.data) &&
                    dealOfDayList?.data?.length > 0 &&
                    dealOfDayList?.data.map((res, index) => {
                      return (
                        <>
                          {index + 1 === 1 ? (
                            <Col lg={4} md={6}>
                              <div className="deal-card-inner">
                                <Link
                                  to={`/single-dish/${res?._id}`}
                                  className="no-underline"
                                >
                                  <Row>
                                    <Col lg={6}>
                                      <div className="deal-card-left">
                                        <h2>{res?.name || "N/A"}</h2>
                                        {/* <h6>Lorem Ipsum is dummy text.</h6> */}
                                        <p>{res?.description || "N/A"}</p>
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="deal-card-right">
                                        <img
                                          src={
                                            res?.images?.length > 0
                                              ? ImagePath(res?.images[0])
                                              : require("../Assets/images/hillshire-farm-U0BzBTt-5so-unsplash.png")
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Link>
                              </div>
                            </Col>
                          ) : index + 1 === 2 ? (
                            <Col lg={4} md={6}>
                              <div className="deal-card-inner reverse">
                                <Link
                                  to={`/single-dish/${res?._id}`}
                                  className="no-underline"
                                >
                                  <Row>
                                    <Col lg={6}>
                                      <div className="deal-card-right">
                                        <img
                                          src={
                                            res?.images?.length > 0
                                              ? ImagePath(res?.images[0])
                                              : require("../Assets/images/hillshire-farm-U0BzBTt-5so-unsplash.png")
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="deal-card-left">
                                        <h2>{res?.name || "N/A"}</h2>
                                        {/* <h6>Lorem Ipsum is dummy text.</h6> */}
                                        <p>{res?.description || "N/A"}</p>
                                      </div>
                                    </Col>
                                  </Row>
                                </Link>
                              </div>
                            </Col>
                          ) : (
                            <Col lg={4} md={6}>
                              <div className="deal-card-inner reverse">
                                <Link
                                  to={`/single-dish/${res?._id}`}
                                  className="no-underline"
                                >
                                  <Row>
                                    <Col lg={5} sm={4}>
                                      <div className="deal-card-right">
                                        <img
                                          src={
                                            res?.images?.length > 0
                                              ? ImagePath(res?.images[0])
                                              : require("../Assets/images/hillshire-farm-U0BzBTt-5so-unsplash.png")
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={7} sm={8}>
                                      <div className="deal-card-left">
                                        <h2>{res?.name || "N/A"}</h2>
                                        {/* <h6>Lorem Ipsum is dummy text.</h6> */}
                                        <p>
                                          {res?.description?.length > 100
                                            ? res?.description.slice(0, 100)+"..."
                                            : res?.description || "N/A"}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                </Link>
                              </div>
                            </Col>
                          )}
                        </>
                      );
                    })}
                </Row>
              </div>
            </div>
          </section>
        )}

        {/* Browser Category */}
        {tokenData && (
          <BrowseCategoryHome
            setCategoryId={setCategoryId}
            categoryId={categoryId}
          />
        )}

        {shopList?.data?.most_popular?.length > 0 && (
          <HomeShopList
            data={shopList?.data?.most_popular}
            title={HomeTitles.MOSTPOPULAR}
          />
        )}

        {shopList?.data?.favorties?.length > 0 && (
          <HomeShopList
            data={shopList?.data?.favorties}
            title={HomeTitles.FAVOURITES}
          />
        )}

        {shopList?.data?.proshops?.length > 0 && (
          <HomeShopList
            data={shopList?.data?.proshops}
            title={HomeTitles.PROSHOPS}
          />
        )}

        {shopList?.data?.homemade?.length > 0 && (
          <HomeShopList
            data={shopList?.data?.homemade}
            title={HomeTitles.HOMEBAKERS}
          />
        )}

        {shopList?.data?.trendings?.length > 0 && (
          <HomeShopList
            data={shopList?.data?.trendings}
            title={HomeTitles.TRENDING}
          />
        )}

        {shopList?.data?.near_by?.length > 0 && (
          <HomeShopList
            data={shopList?.data?.near_by}
            title={HomeTitles.NEARBYYOU}
          />
        )}

        {shopList?.data &&
          Object.values(shopList?.data).every(
            (arr) => Array.isArray(arr) && arr.length === 0
          ) && (
            <div className="text-center mt-5">
              <p className="no-data-available"> No data available.</p>
            </div>
          )}
      </Container>

      {tokenData &&
        prepareOrderData?.data &&
        Object.keys(prepareOrderData?.data)?.length !== 0 && (
          <>
            <div className="order-preparing-msg">
              <img src={require("../Assets/images/orderprepare.svg").default} />
              <h2>We are preparing your order</h2>
            </div>
          </>
        )}

      {/* <AllowLocationModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} /> */}
    </Layout>
  );
}
