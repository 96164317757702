import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import useGetMyProfile from "../Hook/useGetUserProfile";
import { ImagePath } from "../utilis/ImageUrl";
import moment from "moment";
import { editProfile, getUserProfile } from "../Redux/Action/AuthAction";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import imageArray from "../utilis/defaultImageArray";

const EditUserProfile = ({ show, handleClose }) => {
  const [file, setFile] = useState(null);
  const userProfileData = useGetMyProfile();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      dob: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      dob: Yup.date()
        .max(new Date(), "Date cannot be in the future")
        .required("Date of Birth is required"),
    }),
    onSubmit: async (values) => {
      console.log(values, "values");
      if (file === null) {
        return toast.warn("Please add an Image");
      }
      let payload = {};
      if (values.profile_pic && typeof values.profile_pic === "string") {
        let newValues = {
          first_name: values?.first_name,
          last_name: values?.last_name,
          dob: values.dob,
          profile_pic: values.profile_pic,
        };
        payload = newValues;
      } else {
        let formData = new FormData();
        if (values?.profile_pic) {
          formData.append("profile_pic", values?.profile_pic);
        }
        formData.append("first_name", values?.first_name);
        formData.append("last_name", values.last_name);
        formData.append("dob", values.dob);
        payload = formData;
      }
      console.log("payload", payload);

      await dispatch(editProfile(payload)).then((res) => {
        console.log("editProfile", res);
        if (res?.payload?.status === 200) {
          dispatch(getUserProfile()).then((response) => {
            if (response) {
              toast.success(res?.payload?.message);
              handleClose();
            }
          });
        } else {
          toast.error(res?.payload?.message);
          handleClose();
        }
      });
    },
  });

  useEffect(() => {
    if (userProfileData) {
      formik.setValues({
        first_name: userProfileData.first_name || "",
        last_name: userProfileData.last_name || "",
        dob: moment(userProfileData.dob).format("DD/MM/YYYY") || "",
      });
      //   if (userProfileData.profile_pic) {
      //     setFile(ImagePath(userProfileData.profile_pic));
      //   }
    }
  }, [userProfileData]);

  const handleChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      // setFile(URL.createObjectURL(file));
      formik.setFieldValue("profile_pic", file);
      setFile(file);
    } else {
      formik.setFieldValue("profile_pic", file);
      setFile("");
    }
  };

  const getImageSrc = () => {
    if (file) {
      return URL.createObjectURL(file);
    } else if (formik?.values?.profile_pic) {
      if (
        formik?.values?.profile_pic &&
        formik?.values?.profile_pic.includes("https")
      ) {
        return formik?.values?.profile_pic;
      } else {
        return `${process.env.REACT_APP_BASE_URL}public/${formik.values.profile_pic}`;
      }
    } else {
      return require("../Assets/images/userico.svg");
    }
  };

  return (
    <>
      <Modal
        className="cmn-modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2 className="heading-25 mb-4 px-4">Edit Profile</h2>
          <form onSubmit={formik.handleSubmit} className="cmn-form-fields px-4">
            <div className="image-upload-input">
              <div className="image-upload-view">
                <img
                  className="view-img"
                  src={file || ImagePath(userProfileData?.profile_pic)}
                  alt="Profile"
                />
                <img
                  src={require("../Assets/images/userico.svg").default}
                  alt="User Icon"
                />
                <img className="view-img" src={getImageSrc()} />
              </div>
              <div className="image-upload-camera">
                <input type="file" onChange={handleChange} />
                <img
                  src={require("../Assets/images/camera.svg").default}
                  alt="Camera Icon"
                />
              </div>
            </div>

            <div className="sub-profile-pic">
              {imageArray.map((image) => (
                <img
                  key={image.id}
                  src={image.url}
                  onClick={() => {
                    setFile("");
                    formik.setFieldValue("profile_pic", image.url);
                  }}
                  alt={`Image ${image.id}`}
                />
              ))}
            </div>

            <Form.Group className="mb-3 form-inner">
              <img
                src={require("../Assets/images/user.svg").default}
                alt="User Icon"
              />
              <Form.Control
                type="text"
                placeholder="First Name"
                {...formik.getFieldProps("first_name")}
                isInvalid={
                  formik.touched.first_name && formik.errors.first_name
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.first_name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 form-inner">
              <img
                src={require("../Assets/images/user.svg").default}
                alt="User Icon"
              />
              <Form.Control
                type="text"
                placeholder="Last Name"
                {...formik.getFieldProps("last_name")}
                isInvalid={formik.touched.last_name && formik.errors.last_name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.last_name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 form-inner">
              <img
                src={require("../Assets/images/date.svg").default}
                alt="Date Icon"
              />
              <Form.Control
                type="date"
                {...formik.getFieldProps("dob")}
                max={new Date().toISOString().split("T")[0]}
                isInvalid={formik.touched.dob && formik.errors.dob}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.dob}
              </Form.Control.Feedback>
            </Form.Group>

            <button type="submit" className="cmn-red-btn mb-0 w-100">
              Save
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditUserProfile;
