import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Rating } from 'react-simple-star-rating';
import { getListOrderAction, orderDetailsAction, orderRatingAction } from '../Redux/Action/OrderAction';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { ImagePath } from '../utilis/ImageUrl';

const OrderFeedBackModal = ({ showFeedback, setShowFeedback, id, pagination, limit }) => {
    const dispatch = useDispatch();
    const { orderDetails } = useSelector((state) => state?.order);
    const [isLoader, setIsLoader] = useState(false);
    const [ratings, setRatings] = useState({}); // Store ratings per product
    const [descriptions, setDescriptions] = useState({}); // Store descriptions per product


    useEffect(() => {
        if (id && setShowFeedback) {
            dispatch(orderDetailsAction({ id }));
        }
    }, [id, dispatch, setShowFeedback]);

    const validationSchema = Yup.object({
        description: Yup.string().required('Description is required'),
    });

    const formik = useFormik({
        initialValues: {
            description: '',
        },
        // validationSchema,
        onSubmit: async (values) => {
            console.log("values", values)
            const reviews = orderDetails?.order_items.map((item) => ({
                product_id: item.product_id._id,
                rating: ratings[item.product_id._id] ?? 0, // Use stored rating or 0
                description: descriptions[item.product_id._id] || '',
            }));

            if (reviews.some((review) => review.rating === 0)) {
                return toast.warn('Please rate all products.');
            }

            if (reviews.some((review) => !review.description)) {
                return toast.warn('Please provide descriptions for all products.');
            }

            const payload = {
                order_id: id,
                reviews,
            };

            console.log("pay", payload)
            setIsLoader(true);
            await dispatch(orderRatingAction(payload)).then((res) => {
                if (res?.payload?.status === 200) {
                    dispatch(getListOrderAction({ status: `2`, pagination, limit }));
                    toast.success(res?.payload?.message);
                } else {
                    toast.error(res?.payload?.message);
                }
                setIsLoader(false);
                setShowFeedback(false);
                formik.resetForm();
                setRatings({});
                setDescriptions({});
            });
        },
    });

    const handleRatingChange = (productId, rating) => {
        setRatings((prev) => ({ ...prev, [productId]: rating }));
    };

    const handleDescriptionChange = (productId, value) => {
        setDescriptions((prev) => ({ ...prev, [productId]: value }));
    };

    return (
        <Modal
            className="cmn-modal"
            show={showFeedback}
            onHide={() => {
                setShowFeedback(false)
                setRatings({});
                setDescriptions({});
            }}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <h2 className="heading-25 mb-4">Order Feedback</h2>
                    <div className="feedback-main">
                        <div className="orders-card">
                            <div className="orderscard-top">
                                <Row>
                                    <Col lg={3}>
                                        <img src={require('../Assets/images/orders.svg').default} />
                                    </Col>
                                    <Col lg={9}>
                                        <h3>Order Id {orderDetails?.order_no || 'N/A'}</h3>
                                        <p>Placed on {moment(orderDetails?.createdAt).format('DD/MM/YYYY')}</p>
                                        <div className="d-flex">
                                            <h4>Item: {orderDetails?.items_quantity || '0'}</h4>
                                            <h4>Price: ${orderDetails?.items_total_price || '0'}</h4>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        {Array.isArray(orderDetails?.order_items) &&
                            orderDetails?.order_items.map((item) => (
                                <>
                                    <div key={item?._id} className="orders-card mt-3">
                                        <div className="orderscard-top">
                                            <Row>
                                                <Col lg={3}>
                                                    <img
                                                        src={
                                                            item?.product_id?.images?.length > 0
                                                                ? ImagePath(item?.product_id?.images[0])
                                                                : require('../Assets/images/cakes.png')
                                                        }
                                                    />
                                                </Col>
                                                <Col lg={9}>
                                                    <h3>{item?.product_id?.name || 'N/A'}</h3>
                                                    <p>{item?.size_id?.name || 'N/A'}</p>
                                                    <div className="d-flex">
                                                        <h6>{item?.shop_id?.name || 'N/A'}</h6>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center mt-4 mb-3">
                                        <Rating
                                            onClick={(rating) => handleRatingChange(item?.product_id?._id, rating)}
                                            ratingValue={ratings[item?.product_id?._id] || 0}
                                        />
                                    </div>

                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            name="description"
                                            as="textarea"
                                            rows={5}
                                            placeholder="Write description here..."
                                            value={descriptions[item?.product_id?._id] || ''}
                                            onChange={(e) =>
                                                handleDescriptionChange(item?.product_id?._id, e.target.value)
                                            }
                                        />
                                    </Form.Group>
                                    {formik.touched.description && formik.errors.description && (
                                        <div className="text-danger">{formik.errors.description}</div>
                                    )}

                                </>

                            ))}


                        <div className="feedback-btns">
                            <button type="submit" className="cmn-red-btn mb-0 w-100">
                                {isLoader && (
                                    <div className="spinner-border spinner-border-sm" role="status"></div>
                                )}{' '}
                                Submit Feedback
                            </button>
                            <button
                                type="button"
                                className="skip w-100"
                                onClick={() => {
                                    setShowFeedback(false)
                                    setRatings({});
                                    setDescriptions({});
                                }}
                            >
                                Skip
                            </button>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default OrderFeedBackModal;