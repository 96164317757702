import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { promoCodeAction } from '../Redux/Action/CartAction'
import { useTranslation } from 'react-i18next'

const PromoCodeModal = ({ show, setShow, formik, setPromoId, cart_ids }) => {
    console.log("cart_ids", cart_ids)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { promoCodeList } = useSelector((state) => state.cart)

    console.log("promoCodeList", promoCodeList)

    const [apiCalled, setApiCalled] = useState(false)

    useEffect(() => {
        if (cart_ids?.length > 0 && !apiCalled) {
            console.log('API call with cart_ids:', cart_ids)
            dispatch(promoCodeAction({ cart_ids: JSON.stringify(cart_ids) }))
            setApiCalled(true)
        }
    }, [cart_ids, apiCalled, dispatch])

    const handlePromoCode = (id, code) => {
        setPromoId(id)
        formik.setFieldValue('promoCode', code)
        setShow(false)
    }

    return (
        <Modal
            className="cmn-modal"
            show={show}
            onHide={() => setShow(false)}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <h2 className="heading-25 mb-4 px-4">
                    {t('checkout.promocode.list')}
                </h2>
                <div className="promocodes-wrap">
                    {Array.isArray(promoCodeList?.data) && promoCodeList?.data.length > 0 ? (
                        promoCodeList.data.map((res) => (
                            <div className="promocode-box mb-3" key={res?._id}>
                                <div className="promocode-top">
                                    <h2>
                                        {res?.discount_type === 1
                                            ? `${t('checkout.promocode.type.perc')} ${res.discount} %`
                                            : `${t('checkout.promocode.type.flat')} $${res.discount}`}{' '}
                                        {t('checkout.promocode.list-desc')}
                                    </h2>
                                    <p>
                                        {t('checkout.promocode.save')}{' '}
                                        {res?.discount_type === 1
                                            ? `${res.discount} %`
                                            : `$${res.discount}`}{' '}
                                        {t('checkout.promocode.code')}
                                    </p>
                                    <h3>{res?.code || 'N/A'}</h3>
                                </div>
                                <div className="promocode-bottom">
                                    <p>{res?.description || 'N/A'}</p>
                                    <div className="d-flex justify-content-center">
                                        <button
                                            type="button"
                                            onClick={() => handlePromoCode(res?._id, res?.code)}
                                        >
                                            {t('checkout.promocode.btn.tap')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>{t('checkout.promocode.placeholder')}</p>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PromoCodeModal
