import { createSlice } from "@reduxjs/toolkit";
import { getListSubscribeAction } from "../Action/SubscriptionAction";

const initialState = {
    subscribeList: []
}

const subscribeSlice = createSlice({
    name: 'subscribe',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListSubscribeAction.fulfilled, (state, action) => {
                state.subscribeList = action?.payload;
            })
    },
});

export default subscribeSlice.reducer;