import { configureStore } from '@reduxjs/toolkit';
import authSlice from './Slice/authSlice';
import addressSlice from './Slice/addressSlice';
import homeSlice from './Slice/homeSlice';
import cartSlice from './Slice/cartSlice';
import orderSlice from './Slice/orderSlice';
import languageSlice from './Slice/languageSlice';
import socketSlice from './Slice/socketSlice';
import subscribeSlice from './Slice/subscribeSlice';
import loaderReducer from './Slice/loaderSlice';


const store = configureStore({
    reducer: {
        authData: authSlice,
        address: addressSlice,
        home: homeSlice,
        cart: cartSlice,
        order: orderSlice,
        lang: languageSlice,
        socket: socketSlice,
        subscribe: subscribeSlice,
        loader: loaderReducer,
    }
});

export default store;